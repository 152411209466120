defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoWinningNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/NumberGames/Scripts/Templates/DatePicker',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumber',
    'DanskeSpil/Domain/Keno/Scripts/Templates/SliderSelector',
    'Shared/Framework/Mithril/Scripts/Helpers/DOMUtils'
  ],
  function (Component, DatePicker, KenoApi, KenoInfo, KenoDictionary, KenoUtils, RowNumber, SliderSelector, DOMUtils) {

    // Component:
    Component('keno-winning-numbers', [KenoDictionary, KenoInfo], function (m, route, settings, property) {

      // Variables:
      var hasTouch = 'ontouchstart' in window;

      // Components:
      var root = {
        controller: function () {

          // Variables:

          var activeClassName = 'active';

          // Utilities:

          var onNumberDown = function (event) {
            // Add active class
            event.target.classList.add(activeClassName);
          };

          var onNumberUp = function (event) {
            var number = parseInt(event.target.dataset.number, 10);

            // Remove active class
            event.target.classList.remove(activeClassName);

            // Alias controller property
            var selectedNumbers = self.selectedNumbers();

            if (selectedNumbers.indexOf(number) === -1) {
              // Add to selected numbers array
              selectedNumbers.push(number);
            } else {
              // Remove from selected numbers array
              selectedNumbers.splice(selectedNumbers.indexOf(number), 1);
            }

            // Update selected numbers
            self.selectedNumbers(selectedNumbers);

            // Redraw view
            m.redraw();
          };

          // Variables:
          var self = this;

          this.d = KenoDictionary.get;

          this.dropdownActive = m.prop(false);
          this.loading = m.prop(true);
          this.numbers = m.prop([2, 3, 4, 5, 6, 7, 8, 9, 10]);
          this.numbersValue = m.prop(9);
          this.property = property;
          this.stakes = m.prop([5, 10, 20, 50, 100]);
          this.stakesValue = m.prop(5);
          this.winningNumbers = m.prop([]);
          this.winnerCategories = m.prop([]);
          this.selectedNumbers = m.prop([]);

          this.property = property;
          this.setings = settings;
          this.completedDrawsDates = m.prop([]);
          this.completedDrawsDatesWithDrawNr = m.prop([]);
          this.selectedDrawDate = m.prop();
          this.selectedDrawNr = m.prop();
          this.isDatepickerOpened = m.prop(false);

          // Functions:
          this.getWinningNumbers = function () {
            var self = this;

            this.loading(true);

            KenoApi.getWinningNumbers({ drawId: self.selectedDrawNr() }).then(function (data) {
              self.winningNumbers(data.winningNumbers || []);
              self.winnerCategories(data.winnerCategories || []);
              self.selectedNumbers([]);
              self.loading(false);
              m.redraw();
            });
          }.bind(this);

          this.selectDate = function (date) {
            this.selectedDrawDate(date);
            this.selectedDrawNr(this.completedDrawsDatesWithDrawNr().filter(function (obj) {
              return obj.date === date;
            })[0].value);

            this.getWinningNumbers();
          }.bind(this);

          this.createNumberConfig = function (number) {
            var addedListeners = false;

            return function ($numberElement) {
              $numberElement.dataset.number = number; // eslint-disable-line no-jquery/no-other-methods

              if (addedListeners) {
                return;
              }

              addedListeners = true;

              DOMUtils.addEventListeners($numberElement, hasTouch ? 'touchstart' : 'mousedown', onNumberDown);

              DOMUtils.addEventListeners($numberElement, hasTouch ? 'touchend' : 'mouseup', onNumberUp);
            };
          };

          this.isWinningNumbersReady = function () {
            // Returns true if selected draw is loaded and have winningNumbers
            return !this.loading() && this.selectedDrawDate() && this.winningNumbers() && this.winningNumbers().length > 0;
          }.bind(this);


          // Context:
          KenoApi.getCompletedDraws().then(function (draws) {
            var completedDraws = [];
            var completedDrawsWithDrawNr = [];
            for (var i = 0; i < draws.length; i++) {
              completedDraws.push(draws[i].date.substring(0, 10));
              completedDrawsWithDrawNr.push({ date: draws[i].date.substring(0, 10), value: draws[i].drawNo });
            }

            this.completedDrawsDates(completedDraws);
            this.selectedDrawDate(completedDraws[0]);
            this.completedDrawsDatesWithDrawNr(completedDrawsWithDrawNr);
            this.loading(false);
            this.getWinningNumbers();
          }.bind(this));

        },

        view: function (controller) {

          var correctNumbers = controller.winningNumbers().filter(function (number) {
            return controller.selectedNumbers().indexOf(number) > -1;
          });

          // For extracing how many winners
          var winnerGroup = (controller.winnerCategories().filter(function (item) {
            return item.numbersPlayed == controller.numbersValue();
          }))[0] || { winners: [] };

          // For extracing how much won
          var prizeGroup = KenoInfo.getPrizeGroups(controller.numbersValue());

          return [
            m('section', { class: 'keno-section bg-white' + (settings.topManchete ? ' manchete top' : '') }, [
              m('div', { class: 'box-wide keno-content-wrapper bring-to-front' }, [
                m('div', { class: 'select-date-section' }, [

                  // Dropdown:
                  m('h2', { class: 'hd bold royalblue' }, controller.d('Winnings/ChooseDate')),
                  (controller.selectedDrawDate() && (controller.completedDrawsDates().length > 0)) ? DatePicker(controller) : null

                ]),
                m('div', { class: 'ticket-config blue-select-bar' }, [
                  m('div', { class: 'box-half' }, [
                    m('h3', { class: 'hd bold royalblue' }, controller.d('Winnings/ChoosePlayedNumbers')),
                    SliderSelector(controller, 'PlayedNumbers', controller.numbers, controller.numbersValue)
                  ]),
                  m('div', { class: 'box-half' }, [
                    m('h3', { class: 'hd bold royalblue' }, controller.d('Winnings/ChooseStakePerRow')),
                    SliderSelector(controller, 'StakePerRow', controller.stakes, controller.stakesValue)
                  ])
                ]),
                m('div', { class: 'numbers-box' }, [
                  m('h3', { class: 'hd bold royalblue' }, controller.d('Winnings/' + (controller.loading() ? 'Loading' : controller.isWinningNumbersReady() ? 'ChooseWinningNumbers' : 'WinningNumbersNotReady'))),

                  !controller.loading() ? m('div', { class: 'number-picker-container' }, [
                    controller.winningNumbers().map(function (number) {
                      return RowNumber(number, controller.selectedNumbers().indexOf(number) > -1 ? ' selected' : '', controller.createNumberConfig(number));
                    })
                  ]) : ''

                ])
              ]),
            ]),

            // Show only table if ready
            controller.isWinningNumbersReady() ?

              m('section', { class: 'keno-section bg-superlightblue' }, [
                m('div', { class: 'box-wide keno-content-wrapper' }, [

                  m('table', { class: 'blue-table' }, [

                    m('thead', { class: 'smaller-font-size' }, [
                      m('tr', [
                        m('th', { colspan: '3' }, controller.d('Winnings/PrizeTableHeader', { numbers: controller.numbersValue(), date: controller.selectedDrawDate() }))
                      ])
                    ]),

                    m('tbody', [

                      m('tr', [
                        m('th', controller.d('Winnings/PrizeTableHeaderCorrectNumbers')),
                        m('th', controller.d('Winnings/PrizeTableHeaderWinners')),
                        m('th', controller.d('Winnings/PrizeTableHeaderPrize', { stake: controller.stakesValue() }))
                      ]),

                      winnerGroup.winners.map(function (prize) {
                        return m('tr' + (prize.correctNumbers == correctNumbers.length ? '.selected' : ''), [
                          m('td', controller.d('Winnings/PrizeTableCellCorrectNumbers', { correctNumbers: prize.correctNumbers })),
                          m('td', prize.winnerCount),
                          m('td', KenoUtils.formatCurrency(controller.stakesValue() * prizeGroup[prize.correctNumbers]))
                        ]);
                      })


                    ])
                  ]),

                ])
              ])
              : ''
          ];

        }
      };

      // Setup routes:
      route('/', root);

    });

  });
