defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/RowHeader',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary'
  ],
  function (m, KenoDictionary) {

    // Template:
    var RowHeader = function (controller, rowNumber) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = KenoDictionary.get;
      var game = controller.game();
      var numbers = game.getRow(currentRow).numbers.length;
      var rows = game.rows();
      var rowsCount = rows.length;
      var notDeletable = rowsCount === 1 || (rowsCount === currentRow && numbers === 0);

      // Functions:
      var remove = function () {
        if (typeof controller.deleteOverlay !== 'undefined') {
          controller.deleteOverlay().show(function () {
            var currentRow = controller.property('currentRow');
            var deleteRow = controller.property('deleteRow');
            var game = controller.game();

            if (deleteRow && currentRow !== deleteRow) {
              game.removeRow(deleteRow);

              if (currentRow > deleteRow) {
                controller.property('currentRow', currentRow - 1);
              }
            } else {
              controller.property('animate', 'remove');

              game.getRow(currentRow).state = 'remove';
            }
          });
        }
      };

      var thisRow = function () {
        return rowNumber === rowsCount ? rowNumber + 2 : rowNumber + 1;
      };

      var totalRows = function () {
        return rowNumber + 1 === rowsCount ? rowsCount : rowsCount - 1;
      };

      // View:
      return m('div', { class: 'row-header' }, [
        m('span', { class: 'counter' }, [
          d('KenoClassic/RowHeaderRow') + ' ',
          m('span', { class: 'counter-current' }, thisRow()),
          ' ' + d('KenoClassic/RowHeaderOf') + ' ',
          m('span', { class: 'counter-total' }, totalRows())
        ]),
        m('div', { class: 'right-side' }, [
          m('span', { class: 'header-rules-text' }, d('KenoClassic/RowHeaderDescription')),
          m('span', { class: 'row-delete-button' + (notDeletable ? ' inactive' : ''), onclick: (notDeletable ? null : remove) }, [
            m('svg', { class: 'icon icon-x-big' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-x-big' })
            ])
          ])
        ])
      ]);
    };

    // Public functions:
    return RowHeader;

  });
