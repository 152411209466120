defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoSystem/LevelWinnings',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary'
  ],
  function (m, KenoDictionary) {

    // Template:
    var LevelWinnings = function (controller) {

      // Variables:
      var d = KenoDictionary.get;
      var game = controller.game();
      var numbersMinimum = game.systemLevel();
      var numberCount = game.getRow(1).numbers.length;

      if (numberCount > 10) {
        numberCount = 10;
      }

      // Function
      var classNames = function () {
        var classNames = '';

        if (numbersMinimum === 10) {
          classNames = ' full-out-of-range';
        }

        return classNames;
      };

      // View:
      return m('div', { class: 'winnings-level-overview' }, [
        m('div', { class: 'out-of-range-numbers' + classNames() }, [
          function () {
            var out = [];

            for (var i = 1; i < numbersMinimum + 1; i++) {
              out.push(m('span', { class: 'number' + (numberCount == i ? ' selected' : '') }, i));
            }

            return out;
          }()
        ]),

        m('div', { class: 'in-range-numbers' }, [
          function () {
            var out = [];

            for (var i = numbersMinimum + 1; i <= 10; i++) {
              var inner = [];

              if (i === numbersMinimum + 1) {
                inner.push(m('span', { class: 'info-text' }, d('WinningsOverview/NotWinningNumbers')));
              }

              inner.push(i);

              out.push(m('span', { class: 'number' + (numberCount == i ? ' selected' : '') }, inner));
            }

            return out;
          }()
        ])
      ]);

    };

    // Public functions:
    return LevelWinnings;

  });
