defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Navigation',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var Navigation = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var game = controller.game();
      var rows = game.rows();
      var hasLastRow = currentRow + 2 <= rows.length;
      var maxRows = game.classicMaxRows();
      var minimum = game.numbersPerRowMin();
      var nextRow = rows.length > currentRow ? game.getRow(currentRow + 1).numbers.length : 0;
      var numbers = game.getRow(currentRow).numbers.length;
      var hasMoreRows = currentRow < rows.length;
      var showCreate = numbers >= minimum && hasMoreRows && nextRow === 0 && rows.length < maxRows + 1;
      var showNext = (numbers >= minimum && hasMoreRows && nextRow > 0) || (hasLastRow);

      // Functions:
      var slide = function (direction) {
        return function () {
          if (direction === 'right' && hasMoreRows && currentRow < maxRows) {
            controller.property('currentRow', currentRow + 1);
            controller.property('animate', direction);
          } else if (direction === 'left' && currentRow > 1) {
            controller.property('currentRow', currentRow - 1);
            controller.property('animate', direction);
          }
        };
      };

      // View:
      return [
        m('div', { class: 'left-gradient', onclick: slide('left') }),
        m('div', { class: 'prev-row-button' + (currentRow > 1 ? ' active' : ''), onclick: slide('left') }, [
          m('svg', { class: 'icon icon-left-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-left-arrow' })
          ])
        ]),
        m('div', { class: 'right-gradient' + (rows.length < maxRows ? ' active' : ''), onclick: slide('right') }),
        m('div', { class: 'create-new-row-button' + (showCreate ? ' active' : ''), onclick: slide('right') }, [
          m('svg', { class: 'icon icon-plus' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-plus' })
          ])
        ]),
        m('div', { class: 'next-row-button' + (showNext ? ' active' : ''), onclick: slide('right') }, [
          m('svg', { class: 'icon icon-right-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-right-arrow' })
          ])
        ])
      ];
    };

    // Public functions:
    return Navigation;

  });
