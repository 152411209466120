defineDs('DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
  ['Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration'],
  function (m, AccountIntegration) {

    // Authenficiation and balance checks:
    var checkAuth = function () {
      var deferred = m.deferred();

      // Auth check:
      AccountIntegration.getLoginStatus().then(function (isLoggedIn) {
        if (isLoggedIn) {
          deferred.resolve();
        } else {
          deferred.reject();
        }
      }, function () {
        deferred.reject();
      });

      return deferred.promise;
    };

    var checkBalance = function (requiredAmount) {
      var deferred = m.deferred();

      AccountIntegration.checkBalance(requiredAmount).then(function (balanceOk) {
        if (!balanceOk) {
          deferred.reject('INSUFFICIENT_AMOUNT');
        } else {
          deferred.resolve();
        }
      }, function () {
      // Something odd went wrong with auth
        deferred.reject('AUTH_FAILED');
      });

      return deferred.promise;

    };

    var authFailed = function (game) {
      console.debug('Auth service failed');
      AccountIntegration.logout();

      console.warn('opening login with ', location.href, game().cancelUrl());

      AccountIntegration.openLogin(location.href, game().cancelUrl());
    };

    var checkAuthAndBalance = function (game) {
      var deferred = m.deferred();
      var requiredAmount = game().totalPrice();

      checkAuth().then(function () {
        checkBalance(requiredAmount).then(function () {
          deferred.resolve();
        }, function (error) {
          console.debug('checkBalance error', error);

          if (error === 'INSUFFICIENT_AMOUNT') {

            var paymentMethod = 'window';

            if (paymentMethod === 'window') {
              deferred.reject('ASK_FOR_DEPOSIT');
            } else if (paymentMethod === 'iframe') {
              AccountIntegration.openDeposit(requiredAmount, function () {
                location.href = game().cancelUrl();
              });
            }



          }

          if (error === 'AUTH_FAILED') {
          // Auth failed
            authFailed(game);
          }

        });
      }, function () {
      // Auth failed
        authFailed(game);
      });

      return deferred.promise;
    };

    var getPurchaseError = function (errorType) {
      var customErrors = {
        'NUMBERGAMES.NO_OPEN_DRAW': 'NoOpenDraw',
        'NUMBERGAMES.OPEN_DRAW_MISMATCH': 'DrawMismatch',
        'NUMBERGAMES.PLAYER_RESTRICTED_FROM_BUY': 'PlayerRestrictedFromBuy',
        'NUMBERGAMES.GAMEPROVIDER_TIMEOUT': 'GameProviderTimeout',
        'CLIENT.TIMEOUT': 'ClientConnectionTimeout',
        'CLIENT.REFUSED': 'ClientConnectionRefused'
      };
      return customErrors[errorType] || 'TechnicalError';
    };


    var handlePurchaseError = function (game, errorMessage, showErrorCallback) {

      if (errorMessage == 'NUMBERGAMES.NO_FUNDS') {
        AccountIntegration.openDeposit(game().totalPrice(), function () {
          location.href = game().cancelUrl();
        });
      } else if (errorMessage == 'USER_UNAUTHORIZED') {
        checkAuthAndBalance();
      } else {
        showErrorCallback(errorMessage);
      }

    };


    return {
      checkAuthAndBalance: checkAuthAndBalance,
      getPurchaseError: getPurchaseError,
      handlePurchaseError: handlePurchaseError
    };


  });
