defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/Row',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/RowHeader',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbersHeader',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbers'
  ],
  function (m, RowHeader, RowNumbersHeader, RowNumbers) {

    // Template:
    var Row = function (controller, row, rowIndex) {

      // Variables:
      var rowNumber = rowIndex + 1;
      var currentRowNumber = controller.property('currentRow');
      var rowsLength = controller.game().rows().length;

      // Functions:
      var rowClass = function () {
        // Class name base
        var className = 'row-container row-number-' + (rowNumber);

        // Add class regarding user interaction
        if (row.numbers.length > 0) {
          className += ' interacted-row';
        }

        // Add class name regarding row position
        if (rowNumber === currentRowNumber) {
          className += ' in-focus';
        } else if (rowNumber === currentRowNumber + 1) {
          className += ' next-row';
        } else if (rowNumber === currentRowNumber + 2) {
          className += ' next-next-row';
        } else if (rowNumber === currentRowNumber - 1) {
          className += ' prev-row';
        } else if (rowNumber === currentRowNumber - 2) {
          className += ' prev-prev-row';
        }

        // Add class name for new row
        if (rowsLength > 1 && rowNumber === rowsLength && currentRowNumber !== rowsLength) {
          className += ' new-row';
        }

        // Add class regarding animation
        if (controller.property('animate') === 'shake' && rowNumber === currentRowNumber) {
          className += ' shake-state';
        }

        return className;
      };

      var createRowConfig = function () {
        return function ($element) {
          // Event functions
          var onAnimationEnd = function () {
            var animate = controller.property('animate');

            if (/shake/.test(animate) && !/shake-end/.test(animate)) {
              controller.property('animate', controller.property('animate') + ' shake-end');

              m.redraw();
            }
          };

          // Event listener
          $element.addEventListener('animationend', onAnimationEnd); // eslint-disable-line no-jquery/no-other-methods

        };
      };

      var slide = function () {
        return function () {
          if (currentRowNumber < rowNumber) {
            controller.property('animate', 'right');
            controller.property('currentRow', currentRowNumber + 1);
          } else if (currentRowNumber > rowNumber) {
            controller.property('animate', 'left');
            controller.property('currentRow', currentRowNumber - 1);
          }
        };
      };

      // Return:
      return m('div', { class: rowClass(), config: createRowConfig(), onclick: slide(), key: 'keno-row-' + row.count }, [
        RowHeader(controller, rowIndex),
        RowNumbersHeader(controller, rowIndex),
        m('div', { class: 'number-picker-container' }, [
          RowNumbers(controller, rowIndex)
        ])
      ]);
    };

    // Public functions:
    return Row;

  });
