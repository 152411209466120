defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoReceiptStep',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/CouponOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration'
  ],
  function (Component, Countdown, CouponOverlay, Overlay, KenoDictionary, KenoUtils, KenoInfo, KenoGame, AccountIntegration) {

    // Component:
    Component('keno-receipt', [AccountIntegration, KenoDictionary, KenoInfo], function (m, route, settings) {

      // Variables:
      var d = KenoDictionary.get;
      var id = KenoUtils.getQueryParam('gameInstanceId');

      // Missing id:
      if (!id) {
        return;
      }

      var game = KenoGame.get(id);

      // Missing game:
      if (!game) {
        console.error('KenoReceiptStep: Game not found, id: ' + id);

        return;
      }

      // If no draw open, go to KenoClosed page
      if (!KenoInfo.data().openDraw) {
        KenoUtils.gotoKenoClosed();
      }

      // Components:
      var root = {
        controller: function () {

          // Variables
          this.game = m.prop(game);
          this.options = m.prop(settings || []);
          this.couponOverlay = m.prop(new Overlay(CouponOverlay('keno-coupon-overlay', this.game().couponId(), { printButton: d('KenoReceiptStep/CouponOverlay/printButton'), closeButton: d('KenoReceiptStep/CouponOverlay/closeButton') })));

          // Functions
          this.back = function () {
            window.history.go(-1);
          };

          this.gotoCoupon = function () {
            this.couponOverlay().show();
            return false;
          }.bind(this);

          var playType = d('PlayTypes/' + this.game().playType(), this.game().playType() == 'KenoSystem' ? { level: this.game().systemLevel() } : {});
          var totalPrice = this.game().totalPrice();
          var rows = this.game().getRows().length;
          var draws = this.game().numberOfDraws();
          var firstName;
          var lastName;
          var userName;

          AccountIntegration.getUserData().then(function (userData) {
            firstName = userData.firstName;
            lastName = userData.lastName;
            userName = userData.userName;
          });

          this.heading = d('KenoReceiptStep/Heading', {
            rows: this.game().playType() == 'KenoSystem' ? this.game().getSystemCombinationAmount() : rows,
            playType: playType,
            totalPrice: totalPrice,
            draws: draws,
            firstName: firstName,
            lastName: lastName,
            userName: userName
          });

          var draw = KenoInfo.data().openDraw;
          var drawDate = draw ? KenoUtils.parseISO8601(draw.closingTime).dateObject : null;
          this.countdownOptions = {
            countdownTime: drawDate ? drawDate.getTime() / 1000 : 0,
            dictionary: d,
            disabled: settings.isCountdownDisabled,
            noOpenDraw: false
          };

          if (this.game().status() == 'completed') {

            // Update balance in UI
            AccountIntegration.triggerBalanceUpdate();

            // Ecommerce tracking
            this.game().trackPurchase();
          }

          // If user logs out - redirect to Keno frontpage
          window.DSAPI && DSAPI.ready(function () {
            DSAPI.Account.subscribeUserLoggedOut({
              callback: function () {
                location.href = (KenoInfo.data().confirmUrl || '').split('/').slice(0, -1).join('/');
              }
            });
          });
        },

        view: function (controller) {

          return m('.keno-receipt', [
            m('.countdown-wrapper', [
              m('.keno-section.bg-white', [
                m('.keno-content-wrapper', [
                  m('.receipt-confirmation', [
                    settings.SelectedIconString != '' ?
                      m('svg', { class: (settings.SelectedIconString != '' ? settings.SelectedIconString : 'checkmark-in-circle') }, [
                        m('use', { class: (settings.SelectedIconString != '' ? settings.SelectedIconString : 'checkmark-in-circle'), href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/SitecoreIcons.svg#' + (settings.SelectedIconString != '' ? settings.SelectedIconString : '') })
                      ])
                      : '',
                    m('p.p.word-width-limit', controller.heading),
                    m('p.p.word-width-limit', [
                      m('a.a', { onclick: controller.gotoCoupon }, d('KenoReceiptStep/GotoCoupon'))
                    ]),
                  ]),
                  m('hr', { class: 'hr mediumblue' }),
                  Countdown(controller.countdownOptions)
                ])
              ])
            ])
          ]);

        }
      };

      // Setup Routes:
      route('/', root);

    });

  });
