defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/RowAutogenerate',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Context:
    var RowAutogenerate = function (controller) {

      // Variables:
      var chosenNumbers = 0;
      var currentRow = controller.property('currentRow');
      var game = controller.game();
      var playType = game.playType();
      var numbers = playType === 'KenoSystem' ? [3, 4, 5, 6, 7, 8, 9, 10] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      var row = game.getRow(currentRow);
      var rowNumbers = row.numbers;
      var rowNumbersAmount = rowNumbers.length;

      for (var i = 0; i < rowNumbersAmount; i++) {
        var rowNumber = rowNumbers[i];

        if (!rowNumber.autogenerated) {
          chosenNumbers++;
        }
      }

      // Functions:
      var autogenerate = function (number) {
        return function () {
          if (!controller.property('autogeneratingNumbers')) {
            controller.property('autogeneratingNumbers', true);
            controller.property('toggledAutogenerate', false);

            var currentRowNumbers = row.numbers;
            var chosen = [];

            for (var i = 0; i < currentRowNumbers.length; i++) {
              var single = currentRowNumbers[i];

              if (!single.autogenerated) {
                chosen.push(single);
              }
            }

            row.numbers = chosen;

            game.setRow(currentRow, row);

            game.autogenerate(currentRow, number).then(function () {
              var numbers = game.getRow(currentRow).numbers;

              for (var i = 0; i < numbers.length; i++) {
                var number = numbers[i];

                if (number.autogenerated) {
                  controller.property('numberSelected' + number.number, 'autogenerated');
                }
              }

              controller.property('autogeneratingNumbers', false);

              m.redraw();
            });
          }
        };
      };

      // View:
      return numbers.map(function (number) {
        return m('div', { class: 'item' + (chosenNumbers >= number ? ' inactive' : '') + (playType === 'KenoSystem' && number < 3 ? ' hidden' : '') + (playType === 'KenoClassic' && number < 2 ? ' hidden' : ''), onclick: chosenNumbers < number && number > 1 ? autogenerate(number) : null }, number);
      });

    };

    // Public functions:
    return RowAutogenerate;

  });
