defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/ConfirmBar',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils'
  ],
  function (m, Render, Scroll, KenoDictionary, KenoUtils) {

    // Template:
    var ConfirmBar = function (controller) {

      // Variables:
      var $bar = null;
      var $container = null;
      var $top = null;
      var d = KenoDictionary.get;
      var game = controller.game();
      var render = Render();

      // Functions:
      var sticky = function () {
        var barHeight = $bar.offsetHeight;
        var topTop = $top ? $top.offsetTop : 0;
        var containerHeight = $container ? $container.offsetHeight : 0;
        var containerTop = $container ? $container.offset().top : 0; // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        var parentWidth = $bar ? $bar.parentElement.clientWidth : 0;
        var scrollTop = window.pageYOffset;
        var windowHeight = window.innerHeight;

        // Viewport:
        var aboveViewport = scrollTop > (topTop === 0 ? containerTop : topTop) + containerHeight;
        var belowViewport = scrollTop + windowHeight < (topTop === 0 ? containerTop : topTop);
        var relativeViewport = scrollTop + windowHeight > containerTop + containerHeight;

        $bar.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $container.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

        // Sticky:
        if (!aboveViewport && !belowViewport && !relativeViewport) {
          $bar.style.bottom = '0';
          $bar.style.position = 'fixed';
          $bar.style.width = parentWidth;
          $container.style.paddingBottom = barHeight + 'px';
        }

        // Dropdown:
        var containerEnd = containerTop + containerHeight;
        var scrollBottom = scrollTop + windowHeight;

        controller.property('confirmBarMaxHeight', ((scrollBottom > containerEnd ? (windowHeight - barHeight) - (scrollBottom - containerEnd) : windowHeight - barHeight) - 20));
      };

      // Render:
      render.ready.then(function (nodes) {
        $container = document.querySelector('.confirm-bar-container');
        $top = document.querySelector('.confirm-bar-top');

        if (!nodes['confirm-bar'].rendered && $container) {

          $bar = nodes['confirm-bar'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

          sticky();

          Scroll(function () {
            sticky();
          });
        }
      });

      // View:
      return m('div', { class: 'confirm-bar-wrapper', config: render.depend('confirm-bar') }, [
        m('div', { class: 'confirm-bar' }, [
          m('div', { class: 'confirm-bar-content' }, [
            m('div', { class: 'confirm-bar-back', onclick: controller.back }, [
              m('a', { class: 'back' }, [
                m('div', { class: 'total-price' }, d('ConfirmBar/BackButton'))
              ])
            ]),
            controller.confirm ? m('div', { class: 'confirm-bar-cta' + (game.status() === 'pending-confirmation' ? ' processing' : ''), onclick: controller.confirm }, [
              m('a', { class: 'confirm' }, [
                m('div', { class: 'purchase-button-loader-box' }, [
                  m('span', { class: 'loader-text' }, d('ConfirmBar/Processing')),
                  m('span', { class: 'loader-animation-box' }, [
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' })
                  ])
                ]),
                m('div', { class: 'total-price' }, d('ConfirmBar/SubmitButton', { totalPrice: KenoUtils.formatNumber(game.totalPrice()) }))
              ])
            ]) : null
          ])
        ])
      ]);

    };

    // Public functions:
    return ConfirmBar;

  });
