defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoConfirmStep',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/AccountIntegration',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Confirm',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Domain/Keno/Scripts/Templates/ConfirmBar',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ReceiveWinnerMessage',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/DataLayer',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, AccountIntegration, Confirm, ErrorOverlay, Overlay, KenoDictionary, KenoInfo, KenoUtils, KenoGame, ConfirmBar, ReceiveWinnerMessage, DataLayer, Ensighten) {

    // Component:
    Component('keno-confirm', [AccountIntegration, KenoDictionary, KenoInfo], function (m, route, settings) {

      // Variables:
      var id = KenoUtils.getQueryParam('gameInstanceId');
      var rebuyCouponId = KenoUtils.getQueryParam('rebuyCouponId');
      var game;

      // Missing id:
      if (!id && !rebuyCouponId) {
        return;
      }

      if (rebuyCouponId && settings.returnToCompletedGamesOverviewLink) {
        var page = KenoUtils.getQueryParam('page') || 1;
        var period = KenoUtils.getQueryParam('period') || 365;
        var offset = KenoUtils.getQueryParam('offset') || 1;
        var type = KenoUtils.getQueryParam('type') || 'afsluttede';
        var channel = KenoUtils.getQueryParam('channel') || 'alle-kanaler';
        game = KenoGame.new({
          rebuyCouponId: rebuyCouponId,
          startUrl: settings.returnToCompletedGamesOverviewLink + '#/' + page + '/' + period + '/' + offset + '/' + type + '/' + channel
        });
      } else {
        game = KenoGame.get(id);
      }

      // Missing game:
      if (!game) {
        console.error('KenoConfirm: Game not found, id: ' + id);

        return;
      }

      // If no draw open, go to KenoClosed page
      if (!KenoInfo.data().openDraw) {
        KenoUtils.gotoKenoClosed(true);
      }

      // Components:
      var root = {
        controller: function () {

          // Variables:
          var self = this;

          this.askForDeposit = m.prop(false);
          this.game = m.prop(game);
          this.properties = m.prop({});
          this.openDraw = m.prop(KenoInfo.data().openDraw || null);
          this.d = KenoDictionary.get;
          this.loading = m.prop(false);
          this.notifyPrizeEmail = m.prop();
          this.notifyPrizeSms = m.prop();
          this.rebuyCouponNotFound = m.prop(false);
          this.showPrizeNotificationSection = m.prop(true);

          this.$rowsWrapperElement = m.prop(null);

          // Functions:
          this.confirmErrorOverlay = function (errorTexts) {
            return new Overlay(ErrorOverlay('keno-classic-game-overlay', errorTexts));
          }.bind(this);

          this.drawDate = function () {
            return m.trust(this.game().drawDateHtml(this.d('ConfirmStep/DrawDateTo')));
          }.bind(this);

          this.back = function () {
            location.href = this.game().cancelUrl();
          }.bind(this);

          this.confirm = function () {
            var self = this;

            if (!this.loading()) {
              this.loading(true);

              this.game().purchase({
                drawId: self.openDraw().id,
                notifyPrizeEmail: self.notifyPrizeEmail(),
                notifyPrizeSms: self.notifyPrizeSms()
              }).then(function () {
                self.loading(false);

                m.redraw();

                var receiptUrl = KenoInfo.data().receiptUrl + '?gameInstanceId=' + self.game().id();

                try {
                  window.history.replaceState({}, self.d('ConfirmStep/ReceiptTitle'), receiptUrl);
                } catch (error) {
                  // Nothing to handle here, just proceed to location.href
                }

                location.href = receiptUrl;
              }, function (data) {
                self.loading(false);

                m.redraw();

                Confirm.handlePurchaseError(self.game, (data && data.errorMessage) || null, self.showError);
              });
            }

            m.redraw(); // for applying "pending" state in view

            return false;
          }.bind(this);

          // Deposit flow:
          this.openDeposit = function () {
            AccountIntegration.openDeposit(this.game().totalPrice(), function () {
              location.href = this.game().cancelUrl();
            });
          }.bind(this);

          this.createRowNumberString = function (val) {
            return Array.prototype.slice.call(val).join(', ');
          };

          this.property = function (name, value) {
            var properties = this.properties();

            if (typeof value === 'undefined') {
              return properties[name];
            } else {
              properties[name] = value;

              this.properties(properties);
            }
          }.bind(this);

          this.showError = function (errorType) {
            var error = Confirm.getPurchaseError(errorType);
            var texts = {
              header: this.d('PurchaseErrors/' + error + 'Header'),
              body: this.d('PurchaseErrors/' + error + 'Body'),
              dismiss: this.d('PurchaseErrors/' + error + 'Dismiss')
            };

            this.confirmErrorOverlay(texts).show();
          }.bind(this);

          this.showExtraRows = function () {
            return function () {
              var $element = this.$rowsWrapperElement();
              var numberOfRows = $element.childNodes.length;
              var rowHeight = $element.firstChild.clientHeight;
              var rowsContainerHeight = numberOfRows * rowHeight;

              $element.style.maxHeight = rowsContainerHeight + 'px';
              $element.classList.add('expanded');
            }.bind(this);
          }.bind(this);

          // Game status class
          this.gameStatusClass = function () {
            return 'status-' + this.game().status();
          }.bind(this);

          if (this.game()) {
            m.startComputation();

            this.game().ready().promise.then(function () {
              Confirm.checkAuthAndBalance(self.game).then(function () {
                AccountIntegration.getUserData().then(function (customerInfo) {

                  self.notifyPrizeEmail(customerInfo.notifyPrizeEMail || false);
                  self.notifyPrizeSms(customerInfo.notifyPrizeSms || false);

                  // Show if notification section if not already notified on either email or sms
                  self.showPrizeNotificationSection(!self.notifyPrizeEmail() && !self.notifyPrizeSms());

                  m.endComputation();
                });
              }, function (error) {
                if (error === 'ASK_FOR_DEPOSIT') {
                  self.askForDeposit(true);

                  // Add ensighten virtualPage event
                  if (self.game) {
                    var playType = DataLayer.categoryName(self.game().playType());
                    Ensighten.pushVirtualPage('tank_op', playType + '/tank_op', 'notEnoughCreditPage');
                  }

                  m.endComputation();
                }
              });
            }, function () {
              self.rebuyCouponNotFound(true);

              m.endComputation();
            });
          }

          // Push addToCart event to dataLayer:
          this.game().ready().promise.then(function () {
            game.trackingAddToCart();
          });

          this.toggleCollapsible = function (ev) {
            ev.currentTarget.parentElement.classList.toggle('confirm-page__collapsible--show');
          };
        },

        view: function (controller) {

          // Rebuy coupon not found:
          if (controller.rebuyCouponNotFound()) {
            controller.confirm = null;
            return m('div', { class: 'keno-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'keno-section confirm-bar-container' }, [
                m('div', { class: 'keno-content-wrapper keno-row-display' }, [
                  m('div', { class: 'keno-confirm-page-header' }, [
                    m('h3', { class: 'row-title' }, controller.d('ConfirmStep/RebuyCouponNotFoundTitle')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/RebuyCouponNotFoundDescription'))
                  ]),
                  ConfirmBar(controller)
                ])
              ])
            ]);
          }

          // Deposit flow:
          if (controller.askForDeposit()) {
            return m('div', { class: 'keno-confirm ' + controller.gameStatusClass() }, [
              m('div', { class: 'keno-section confirm-bar-container' }, [
                m('div', { class: 'keno-content-wrapper keno-row-display' }, [
                  m('div', { class: 'keno-confirm-page-header' }, [
                    m('h3', { class: 'row-title' }, controller.d('ConfirmStep/DepositHeader')),
                    m('p', { class: 'alt-text' }, controller.d('ConfirmStep/DepositText')),
                    m('a', { class: 'btn cta cta-deposit', onclick: controller.openDeposit }, controller.d('ConfirmStep/DepositButton'))
                  ])
                ])
              ])
            ]);
          }

          // If no game object:
          if (!controller.game()) {
            return m('div', { class: 'keno-confirm' }, [
              m('div', { class: 'keno-section confirm-bar-container' + (settings.topManchete || settings.bottomManchete ? ' manchete' : '') + (settings.topManchete ? ' top' : '') + (settings.bottomManchete ? ' bot' : '') }, [
                m('div', { class: 'keno-content-wrapper keno-row-display' }, [
                  m('p', { class: 'alt-text' }, controller.d('ConfirmStep/GameInstanceNotFound'))
                ])
              ])
            ]);
          }

          // View


          var rows = game.getRowsSummary();
          var gameDrawDates = game.drawDates();

          // Return:
          return m('.confirm-page__content', {
            class: controller.gameStatusClass(),
            'data-uitest-id': 'Confirm__content'
          }, [
            // title.
            m('.confirm-page__title', m.trust(controller.d('ConfirmPage/Title', {
              playType: controller.d('PlayTypes/' + game.playType(), game.playType() === 'KenoSystem' ? { level: game.systemLevel() } : {})
            }))),

            // draw dates.
            gameDrawDates.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--draw-dates'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--draw-dates-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-draw', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#keno-draw'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableDraw')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                  }, game.drawDateHtmlShort()),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                m('.confirm-page__table', [
                  gameDrawDates.map(function (date, i) {
                    return m('.confirm-page__table-row', [
                      m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                      m('.confirm-page__table-cell', {
                        'data-uitest-id': 'Confirm__collapsible--draw-dates-date'
                      }, KenoUtils.getLongDate(date.draw))
                    ]);
                  })
                ])
              ])
            ]) : null,

            // price per row.
            game.stakePerRow()
              ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--price-per-row'
              },
              m('.confirm-page__collapsible-top',
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-stake-per-row', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#keno-stake-per-row'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/StakePerRow')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--price-per-row-price'
                  }, KenoUtils.formatCurrency(game.stakePerRow())),
                ])
              )
              ) : null,

            // rows.
            rows.length > 0 ? m('.confirm-page__collapsible.confirm-page__collapsible--can-open', {
              'data-uitest-id': 'Confirm__collapsible--rows'
            }, [
              m('.confirm-page__collapsible-top', {
                onclick: controller.toggleCollapsible,
                'data-uitest-id': 'Confirm__collapsible--rows-toggle'
              }, [
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon',
                    m('svg.confirm-page__collapsible-icon-rows', m('use', {
                      href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#keno-rows'
                    }))
                  ),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableRows')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--rows-rows'
                  }, game.getRowAmount()),
                ])
              ]),
              m('.confirm-page__collapsible-bottom', [
                game.playType() === 'System'
                  ? [
                    m('.confirm-page__table-header', m.trust(controller.d('ConfirmPage/TableChosenSystemNumbers')) + ':'),
                    m('.confirm-page__table', [
                      m('.confirm-page__table-row',
                        m('.confirm-page__table-cell.confirm-page__table-cell--center', { 'data-uitest-id': 'Confirm__collapsible--rows-row' },
                          game.rows()[0].numbers.map(function (val) {
                            return val.number;
                          }).join(', ')
                        )
                      )
                    ])
                  ]
                  : m('.confirm-page__table', [
                    rows.map(function (val, i) {
                      return m('.confirm-page__table-row', [
                        m('.confirm-page__table-cell.confirm-page__table-cell--index', (i + 1) + '.'),
                        m('.confirm-page__table-cell', {
                          'data-uitest-id': 'Confirm__collapsible--rows-row'
                        }, val.join(', ')),

                        game.playType() === 'Classic' ?
                          m('.confirm-page__table-cell-icon-delete',
                            {
                              onclick: controller.deleteRow.bind(controller, i + 1),
                              'data-uitest-id': 'Confirm__collapsible--rows-classic-row-delete'
                            },
                            m('svg', m('use', { href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesGenericCommonIcons.svg#icon-x' }))
                          ) : null,
                      ]);
                    })
                  ]),
              ])
            ]) : null,

            // days.
            game.numberOfDraws()
              ? m('.confirm-page__collapsible', {
                'data-uitest-id': 'Confirm__collapsible--days'
              },
              m('.confirm-page__collapsible-top',
                m('.confirm-page__collapsible-top-row', [
                  m('.confirm-page__collapsible-top-row-icon', m('svg.confirm-page__collapsible-icon-calendar', m('use', {
                    href: '/Components/DanskeSpil/Domain/NumberGames/Graphics/SpriteSheets/NumberGamesCommonIcons.svg#keno-calendar'
                  }))),
                  m('.confirm-page__collapsible-top-row-highlight-text', controller.d('ConfirmPage/TableDays')),
                  m('.confirm-page__collapsible-top-row-text', {
                    'data-uitest-id': 'Confirm__collapsible--days-days'
                  }, game.numberOfDraws()),
                ])
              )
              ) : null,

            // total.
            game.totalPrice() ? m('.confirm-page__total', {
              'data-uitest-id': 'Confirm__collapsible--total'
            }, [
              m('.confirm-page__total-label', controller.d('ConfirmPage/TableTotal')),
              m('.confirm-page__total-amount', {
                'data-uitest-id': 'Confirm__collapsible--total-amount'
              }, KenoUtils.formatCurrency(game.totalPrice())),
            ]) : null,

            // terms and conditions.
            m('.confirm-page__terms', {
              'data-uitest-id': 'Confirm__terms'
            }, [
              controller.d('ConfirmPage/GameVendor')
                ? controller.d('ConfirmPage/GameVendor')
                : null,

              controller.d('ConfirmPage/GameVendor') && controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('span', ' - ') : '',

              controller.d('ConfirmPage/TermsLink') && controller.d('ConfirmPage/ViewTerms')
                ? m('a', {
                  href: controller.d('ConfirmPage/TermsLink')
                }, controller.d('ConfirmPage/ViewTerms'))
                : null
            ]),

            // show price notification.
            controller.showPrizeNotificationSection() ? ReceiveWinnerMessage(controller, 'keno', '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#' + settings.iconUrl) : '',

            // confirm bar.
            ConfirmBar(controller),
          ]);
        }
      };


      // Setup routes:
      route('/', root);

    });

  });
