defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/PurchaseBar',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Resize',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
  ],
  function (OutsideClick, m, Render, Resize, Scroll, KenoDictionary, KenoUtils) {

    // View:
    var PurchaseBar = function (controller) {

      // Variables:
      var $bar = null;
      var $container = null;
      var $top = null;
      var $wrapper = window;
      var d = KenoDictionary.get;
      var game = controller.game();
      var numberOfDraws = game.numberOfDraws();
      var playType = game.playType().toLowerCase();
      var render = Render();

      // Functions:
      var getHeaderHeight = function () {
        var $header = document.querySelector('.top-navigation');

        return $header ? ((parseInt($header.style.transform.split(',')[5], 10) || 0) < 0 ? 0 : $header.offsetHeight) : 0;
      };

      var getDaysStyle = function () {
        var height = controller.property('purchaseBarDaysHeight');
        var dayListItemHeight = controller.property('dayListItemHeight');
        var maxHeight = controller.property('purchaseBarMaxHeight') - getHeaderHeight();

        if (maxHeight < dayListItemHeight * 2.5) {
          maxHeight = dayListItemHeight * 2.5;
        }

        return controller.property('purchaseBarDays') ? 'max-height: ' + (height < maxHeight ? height : maxHeight) + 'px;' : '';
      };

      var getStakeStyle = function () {
        var height = controller.property('purchaseBarStakeHeight');
        var stakeListItemHeight = controller.property('stakeListItemHeight');
        var maxHeight = controller.property('purchaseBarMaxHeight') - getHeaderHeight();

        if (maxHeight < stakeListItemHeight * 2.5) {
          maxHeight = stakeListItemHeight * 2.5;
        }

        return controller.property('purchaseBarStake') ? 'max-height: ' + (height < maxHeight ? height : maxHeight) + 'px;' : '';
      };

      var formatDraws = function (draws) {
        return d(draws < 2 ? 'PurchaseBar/NumberOfDrawsSingular' : 'PurchaseBar/NumberOfDrawsPluralis', { draws: draws });
      };

      var setDraws = function (value) {
        return function () {
          game.setNumberOfDraws(value);
        };
      };

      var setStake = function (value) {
        return function () {
          game.setStakePerRow(value);
        };
      };

      var sticky = function () {
        var barHeight = $bar ? $bar.offsetHeight : 0;
        var topTop = $top ? $top.offsetTop : 0;
        var containerHeight = $container ? $container.offsetHeight : 0;
        var containerTop = $container ? $container.offsetTop : 0;
        var parentWidth = $bar ? $bar.parentElement.clientWidth : 0;
        var scrollTop = $wrapper.pageYOffset;
        var windowHeight = window.innerHeight;

        // Viewport:
        var aboveViewport = scrollTop > (topTop === 0 ? containerTop : topTop) + containerHeight;
        var belowViewport = scrollTop + windowHeight < (topTop === 0 ? containerTop : topTop);
        var relativeViewport = !aboveViewport && scrollTop + windowHeight > containerTop + containerHeight;

        $bar && $bar.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
        $container && $container.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

        // Sticky:
        if (!aboveViewport && !belowViewport && !relativeViewport) {
          if ($bar) {
            $bar.style.bottom = '0';
            $bar.style.position = 'fixed';
            $bar.style.width = parentWidth + 'px';
          }
          if ($container) {
            $container.style.paddingBottom =  barHeight + 'px';
          }
        }

        // Dropdown:
        var containerEnd = containerTop + containerHeight;
        var scrollBottom = scrollTop + windowHeight;

        controller.property('purchaseBarMaxHeight', (scrollBottom > containerEnd ? (windowHeight - barHeight) - (scrollBottom - containerEnd) : windowHeight - barHeight) - 20);
      };

      var toggleDays = function () {
        controller.property('purchaseBarDays', !controller.property('purchaseBarDays'));
        controller.property('purchaseBarStake', false);
      };

      var toggleStake = function () {
        controller.property('purchaseBarDays', false);
        controller.property('purchaseBarStake', !controller.property('purchaseBarStake'));
      };

      // Render:
      render.ready.then(function (nodes) {

        $bar = nodes['purchase-bar'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
        $container = document.querySelector('.purchase-bar-container');
        $top = document.querySelector('.purchase-bar-top');

        if ($bar) {
          setTimeout(function () {
            sticky();
          }, 250);
        }

        if (!nodes['purchase-bar'].rendered && $container) {
          setTimeout(function () {
            sticky();
          }, 50);

          Scroll(function () {
            sticky();
          });

          Resize(function () {
            sticky();
          });

          OutsideClick(nodes['purchase-bar'].$element, nodes['purchase-bar'].context, function () {
            controller.property('purchaseBarDays', false);
            controller.property('purchaseBarStake', false);
          });
        }

        if (!nodes['stake-list'].rendered) {
          controller.property('purchaseBarStakeHeight', (nodes['stake-list'].$element).offsetHeight);
          controller.property('stakeListItemHeight', (nodes['stake-list-item'].$element).offsetHeight);
        }

        if (!nodes['days-list'].rendered) {
          controller.property('purchaseBarDaysHeight', (nodes['days-list'].$element).offsetHeight);
          controller.property('dayListItemHeight', (nodes['day-list-item'].$element).offsetHeight);
        }
      });

      // Return:
      return m('div', { class: 'purchase-bar-wrapper', config: render.depend('purchase-bar') }, [
        m('div', { class: 'purchase-bar' }, [
          m('div', { class: 'purchase-bar-content' }, [
            m('div', { class: 'stake-per-row dropdown-wrapper', config: render.depend('stake-per-row'), onclick: toggleStake }, [
              m('div', { class: 'arrow-up-icon' + (getStakeStyle() !== '' ? ' hide' : '') }, [
                m('svg', { class: 'svg-icon-arrow-up' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#uparrow-small' })
                ])
              ]),
              d('PurchaseBar/Stake', { stake: game.stakePerRow() }),
              m('span', d('PurchaseBar/PerRow')),
              m('div', { class: 'dropdown-list-wrapper', style: getStakeStyle() }, [
                m('ul', { class: 'select-stake dropdown', config: render.depend('stake-list') }, [
                  m('li', { config: render.depend('stake-list-item') }, d('PurchaseBar/StakeDropdownHeader')),
                  game.stakeOptions().map(function (stake) {
                    return m('li', { onclick: setStake(stake) }, [
                      d('PurchaseBar/Stake', { stake: stake }),
                      m('span', d('PurchaseBar/PerRow'))
                    ]);
                  })
                ])
              ])
            ]),
            m('div', { class: 'number-of-draws dropdown-wrapper', config: render.depend('draws-per-row'), onclick: toggleDays }, [
              m('div', { class: 'arrow-up-icon' + (getDaysStyle() !== '' ? ' hide' : '') }, [
                m('svg', { class: 'svg-icon-arrow-up' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#uparrow-small' })
                ])
              ]),
              playType === 'kenomillion' && numberOfDraws === 0 ? [
                m('span', d('PurchaseBar/PlayRowsAsSubscription'))
              ] : [
                m('span', d('PurchaseBar/PlayRowsFor')),
                formatDraws(numberOfDraws)
              ],
              m('div', { class: 'dropdown-list-wrapper', style: getDaysStyle() }, [
                m('ul', { class: 'select-days dropdown', config: render.depend('days-list') }, [
                  m('li', { config: render.depend('day-list-item') }, d('PurchaseBar/DrawsDropdownHeader')),
                  playType === 'kenomillion' ? m('li', { onclick: setDraws(0) }, d('PurchaseBar/PlayRowsFor') + d('PurchaseBar/PlayRowsEveryWeek')) : null,
                  game.numberOfDrawsOptions().map(function (draws) {
                    return m('li', { onclick: setDraws(draws) }, [
                      m('span', d('PurchaseBar/PlayRowsFor')),
                      formatDraws(draws)
                    ]);
                  })
                ])
              ])
            ]),
            m('div', { class: 'purchase-bar-cta ' + (game.totalPrice() > 0 || numberOfDraws === 0 ? 'is-active' : 'is-inactive'), onclick: controller.purchase }, [
              m('a', { class: 'purchase' }, [
                m('div', { class: 'purchase-button-loader-box' }, [
                  m('span', { class: 'loader-text' }, d('PurchaseBar/Processing')),
                  m('span', { class: 'loader-animation-box' }, [
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' }),
                    m('span', { class: 'loader-dot' })
                  ])
                ]),
                m('div', { class: 'total-price' }, d('PurchaseBar/SubmitButton' + (numberOfDraws === 0 ? 'Plus' : ''), { totalstake: KenoUtils.formatNumber(game.totalPrice()) }))
              ])
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return PurchaseBar;

  });
