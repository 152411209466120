defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Barometer',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils'
  ],
  function (m, KenoDictionary, KenoUtils) {

    // View:
    var Barometer = function (controller, settings) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = KenoDictionary.get;
      var game = controller.game();
      var numbers = game.getRow(currentRow).numbers.length;

      // Functions:
      var getClassNames = function () {
        var classNames = 'keno-section bg-dodgerblue keno-barometer-section bg-royalblue';
        var currentNumbers = controller.property('currentNumbers');

        if (currentNumbers && currentNumbers !== '' && numbers > 1) {
          classNames += ' ' + currentNumbers + '-winnings';
        }

        if (controller.property('barometerSticky')) {
          classNames += ' sticky';
        }

        return classNames;
      };

      var getProgress = function () {
        var percentage = '0';

        if (numbers > 1) {
          percentage = numbers * 10;
        }

        return 'width: ' + percentage + '%;';
      };

      var getSectionConfig = function () {
        return function ($element, rendered) {
          if (!rendered) {
            $element.addEventListener('animationend', function () { // eslint-disable-line no-jquery/no-other-methods
              controller.property('currentNumbers', '');
              m.redraw();
            });
          }
        };
      };

      var totalWinnings = function () {
        return KenoUtils.formatCurrency(game.calculateWinnings(currentRow));
      };

      // View:
      return m('div', { class: getClassNames(), config: getSectionConfig() }, [
        settings.heading ? m('span', { class: 'game-heading' }, settings.heading) : undefined,
        !settings.heading && settings.svgIconName ?
          m('div', { class: 'game-heading' }, [
            m('svg', { class: settings.svgIconName }, [
              m('use', { class: settings.svgIconName, href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/SitecoreIcons.svg#' + settings.svgIconName })
            ])
          ]) : null,

        m('div', { class: 'keno-barometer' }, [
          m('svg', { class: 'money-coin coin-one' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-money' })
          ]),
          m('svg', { class: 'money-coin coin-two' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-money' })
          ]),
          m('svg', { class: 'money-coin coin-three' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-money' })
          ]),
          m('div', { class: 'left-bags-container' }, [
            m('svg', { class: 'small-bag bag-one', viewBox: '0 0 200 303' }, [
              m('g', { class: 'shadow' }, [
                m('path', { d: 'M100,281c55.2,0,100,4.9,100,11c0,6.1-44.8,11-100,11S0,298.1,0,292C0,285.9,44.8,281,100,281z' })
              ]),
              m('g', { class: 'bag' }, [
                m('path', { d: 'M155.4,1.7c-8.7-5.1-21.3,2.1-35.9,10.4c-6.4,3.6-16.1,9.2-19.5,9.3c-3.4-0.1-13-5.7-19.5-9.3   C65.9,3.8,53.3-3.4,44.6,1.7c-2.8,1.6-6.2,5.2-6.2,12.7c0,3.3,1.1,9,10.2,20.5c4.5,5.7,9,10.2,9.2,10.4l2,2h32.7h14.8h32.7l2-2   c0.2-0.2,4.7-4.7,9.2-10.4c9.2-11.5,10.2-17.2,10.2-20.5C161.5,6.9,158.2,3.4,155.4,1.7z M93,33.7H65.6c-1.6-1.7-3.9-4.3-6.2-7.2   c-7.2-8.9-7.4-12.2-7.4-12.2c0-0.3,0-0.5,0-0.7c1.1,0.2,3.2,0.7,6.9,2.3c4.5,2,9.8,5,14.8,7.9c7.1,4,13.6,7.7,19.2,9.6   C93,33.6,93,33.6,93,33.7z M140.6,26.4c-2.3,2.9-4.7,5.5-6.3,7.2h-27.6c0,0,0-0.1,0-0.1c5.6-1.9,12.2-5.6,19.4-9.7   C131.2,21,136.5,18,141,16c3.7-1.7,5.8-2.2,6.9-2.3c0,0.2,0,0.4,0,0.7C147.9,14.4,147.7,17.6,140.6,26.4z' }),
                m('path', { d: 'M100.2,55h-0.5H62.6c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3,6.8,6.8,6.8h37.2h0.5h37.2c3.8,0,6.8-3.1,6.8-6.8   c0-3.8-3-6.8-6.8-6.8H100.2z' }),
                m('path', { d: 'M99.6,226.4c0.3,0,0.6,0,0.9,0L99.6,226.4L99.6,226.4z' }),
                m('path', { d: 'M190.5,149.1c-5-12.4-11.9-24.8-20.6-37c-14.7-20.7-29.3-33.5-29.9-34l-1.9-1.7H61.9L60,78.1   c-0.6,0.5-15.2,13.3-29.9,34c-8.7,12.2-15.6,24.7-20.6,37C3.2,164.8,0,180.3,0,195.3c0,9,3.5,17,10.3,23.5   c10.5,10.1,29.1,16.7,55.1,19.6c11.4,1.3,21.6,1.5,28.1,1.5c3.3,0,9.8,0,13.1,0c6.5,0,16.7-0.3,28-1.5c26-2.9,44.6-9.5,55.1-19.6   c6.8-6.5,10.3-14.5,10.3-23.5C200,180.3,196.8,164.8,190.5,149.1z M180.3,209c-14.6,14-51.5,17.2-71.9,17.4v0h-0.5   c-0.3,0-0.6,0-0.9,0v0H93l0,0c-20.1,0-58.3-3-73.3-17.4c-4.1-3.9-6.1-8.4-6.1-13.7c0-30.1,14.9-57.5,27.4-75.1   C51.8,104.9,62.8,94,67.1,90h65.9c9.6,9,53.5,53.1,53.5,105.3C186.4,200.6,184.4,205.1,180.3,209z' }),
                m('path', { d: 'M94.4,178.3c0.3,0.5,0.2,1-0.2,1.5c-0.4,0.5-0.9,0.8-1.4,0.8h-9.2c-0.6,0-1.1-0.1-1.4-0.4   c-0.3-0.3-0.7-0.6-1-1.1l-9-15.6L69,178.8c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5h-8.4c-0.5,0-0.8-0.2-1.1-0.5   c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5h8.4c0.5,0,0.8,0.2,1.1,0.5   c0.3,0.3,0.4,0.7,0.3,1.2L75,150.7l13.1-13.5c0.2-0.3,0.5-0.6,1-0.9c0.4-0.3,1-0.5,1.8-0.5h10.6c0.6,0,0.9,0.2,1.1,0.7   c0.2,0.5,0.1,0.9-0.3,1.3l-19.9,20.3L94.4,178.3z' }),
                m('path', { d: 'M127,135.8c2.5,0,4.8,0.3,6.9,1c2.1,0.7,3.9,1.6,5.4,2.9c1.4,1.3,2.5,2.9,3.1,4.8c0.6,1.9,0.7,4.1,0.2,6.6   c-0.7,3.2-1.9,5.7-3.6,7.6c-1.7,1.9-4,3.4-6.6,4.5l4.7,15.2c0,0.2,0,0.4,0,0.7c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.3-0.7,0.4-1.1,0.4h-8   c-0.9,0-1.4-0.2-1.7-0.6c-0.3-0.4-0.5-0.8-0.6-1.2l-4.1-13.7h-6.5l-2.9,13.7c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5   h-8.4c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5H127z    M116.3,155.1h7.2c1.8,0,3.3-0.3,4.7-1c1.3-0.7,2.2-1.9,2.6-3.7c0.3-1.8,0-3-1-3.7c-1-0.7-2.4-1-4.2-1h-7.2L116.3,155.1z' })
              ])
            ]),
            m('svg', { class: 'small-bag bag-two', viewBox: '0 0 200 303' }, [
              m('g', { class: 'shadow' }, [
                m('path', { d: 'M100,281c55.2,0,100,4.9,100,11c0,6.1-44.8,11-100,11S0,298.1,0,292C0,285.9,44.8,281,100,281z' })
              ]),
              m('g', { class: 'bag' }, [
                m('path', { d: 'M155.4,1.7c-8.7-5.1-21.3,2.1-35.9,10.4c-6.4,3.6-16.1,9.2-19.5,9.3c-3.4-0.1-13-5.7-19.5-9.3   C65.9,3.8,53.3-3.4,44.6,1.7c-2.8,1.6-6.2,5.2-6.2,12.7c0,3.3,1.1,9,10.2,20.5c4.5,5.7,9,10.2,9.2,10.4l2,2h32.7h14.8h32.7l2-2   c0.2-0.2,4.7-4.7,9.2-10.4c9.2-11.5,10.2-17.2,10.2-20.5C161.5,6.9,158.2,3.4,155.4,1.7z M93,33.7H65.6c-1.6-1.7-3.9-4.3-6.2-7.2   c-7.2-8.9-7.4-12.2-7.4-12.2c0-0.3,0-0.5,0-0.7c1.1,0.2,3.2,0.7,6.9,2.3c4.5,2,9.8,5,14.8,7.9c7.1,4,13.6,7.7,19.2,9.6   C93,33.6,93,33.6,93,33.7z M140.6,26.4c-2.3,2.9-4.7,5.5-6.3,7.2h-27.6c0,0,0-0.1,0-0.1c5.6-1.9,12.2-5.6,19.4-9.7   C131.2,21,136.5,18,141,16c3.7-1.7,5.8-2.2,6.9-2.3c0,0.2,0,0.4,0,0.7C147.9,14.4,147.7,17.6,140.6,26.4z' }),
                m('path', { d: 'M100.2,55h-0.5H62.6c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3,6.8,6.8,6.8h37.2h0.5h37.2c3.8,0,6.8-3.1,6.8-6.8   c0-3.8-3-6.8-6.8-6.8H100.2z' }),
                m('path', { d: 'M99.6,226.4c0.3,0,0.6,0,0.9,0L99.6,226.4L99.6,226.4z' }),
                m('path', { d: 'M190.5,149.1c-5-12.4-11.9-24.8-20.6-37c-14.7-20.7-29.3-33.5-29.9-34l-1.9-1.7H61.9L60,78.1   c-0.6,0.5-15.2,13.3-29.9,34c-8.7,12.2-15.6,24.7-20.6,37C3.2,164.8,0,180.3,0,195.3c0,9,3.5,17,10.3,23.5   c10.5,10.1,29.1,16.7,55.1,19.6c11.4,1.3,21.6,1.5,28.1,1.5c3.3,0,9.8,0,13.1,0c6.5,0,16.7-0.3,28-1.5c26-2.9,44.6-9.5,55.1-19.6   c6.8-6.5,10.3-14.5,10.3-23.5C200,180.3,196.8,164.8,190.5,149.1z M180.3,209c-14.6,14-51.5,17.2-71.9,17.4v0h-0.5   c-0.3,0-0.6,0-0.9,0v0H93l0,0c-20.1,0-58.3-3-73.3-17.4c-4.1-3.9-6.1-8.4-6.1-13.7c0-30.1,14.9-57.5,27.4-75.1   C51.8,104.9,62.8,94,67.1,90h65.9c9.6,9,53.5,53.1,53.5,105.3C186.4,200.6,184.4,205.1,180.3,209z' }),
                m('path', { d: 'M94.4,178.3c0.3,0.5,0.2,1-0.2,1.5c-0.4,0.5-0.9,0.8-1.4,0.8h-9.2c-0.6,0-1.1-0.1-1.4-0.4   c-0.3-0.3-0.7-0.6-1-1.1l-9-15.6L69,178.8c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5h-8.4c-0.5,0-0.8-0.2-1.1-0.5   c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5h8.4c0.5,0,0.8,0.2,1.1,0.5   c0.3,0.3,0.4,0.7,0.3,1.2L75,150.7l13.1-13.5c0.2-0.3,0.5-0.6,1-0.9c0.4-0.3,1-0.5,1.8-0.5h10.6c0.6,0,0.9,0.2,1.1,0.7   c0.2,0.5,0.1,0.9-0.3,1.3l-19.9,20.3L94.4,178.3z' }),
                m('path', { d: 'M127,135.8c2.5,0,4.8,0.3,6.9,1c2.1,0.7,3.9,1.6,5.4,2.9c1.4,1.3,2.5,2.9,3.1,4.8c0.6,1.9,0.7,4.1,0.2,6.6   c-0.7,3.2-1.9,5.7-3.6,7.6c-1.7,1.9-4,3.4-6.6,4.5l4.7,15.2c0,0.2,0,0.4,0,0.7c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.3-0.7,0.4-1.1,0.4h-8   c-0.9,0-1.4-0.2-1.7-0.6c-0.3-0.4-0.5-0.8-0.6-1.2l-4.1-13.7h-6.5l-2.9,13.7c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5   h-8.4c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5H127z    M116.3,155.1h7.2c1.8,0,3.3-0.3,4.7-1c1.3-0.7,2.2-1.9,2.6-3.7c0.3-1.8,0-3-1-3.7c-1-0.7-2.4-1-4.2-1h-7.2L116.3,155.1z' })
              ])
            ]),
            m('svg', { class: 'small-bag bag-three', viewBox: '0 0 200 303' }, [
              m('g', { class: 'shadow' }, [
                m('path', { d: 'M100,281c55.2,0,100,4.9,100,11c0,6.1-44.8,11-100,11S0,298.1,0,292C0,285.9,44.8,281,100,281z' })
              ]),
              m('g', { class: 'bag' }, [
                m('path', { d: 'M155.4,1.7c-8.7-5.1-21.3,2.1-35.9,10.4c-6.4,3.6-16.1,9.2-19.5,9.3c-3.4-0.1-13-5.7-19.5-9.3   C65.9,3.8,53.3-3.4,44.6,1.7c-2.8,1.6-6.2,5.2-6.2,12.7c0,3.3,1.1,9,10.2,20.5c4.5,5.7,9,10.2,9.2,10.4l2,2h32.7h14.8h32.7l2-2   c0.2-0.2,4.7-4.7,9.2-10.4c9.2-11.5,10.2-17.2,10.2-20.5C161.5,6.9,158.2,3.4,155.4,1.7z M93,33.7H65.6c-1.6-1.7-3.9-4.3-6.2-7.2   c-7.2-8.9-7.4-12.2-7.4-12.2c0-0.3,0-0.5,0-0.7c1.1,0.2,3.2,0.7,6.9,2.3c4.5,2,9.8,5,14.8,7.9c7.1,4,13.6,7.7,19.2,9.6   C93,33.6,93,33.6,93,33.7z M140.6,26.4c-2.3,2.9-4.7,5.5-6.3,7.2h-27.6c0,0,0-0.1,0-0.1c5.6-1.9,12.2-5.6,19.4-9.7   C131.2,21,136.5,18,141,16c3.7-1.7,5.8-2.2,6.9-2.3c0,0.2,0,0.4,0,0.7C147.9,14.4,147.7,17.6,140.6,26.4z' }),
                m('path', { d: 'M100.2,55h-0.5H62.6c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3,6.8,6.8,6.8h37.2h0.5h37.2c3.8,0,6.8-3.1,6.8-6.8   c0-3.8-3-6.8-6.8-6.8H100.2z' }),
                m('path', { d: 'M99.6,226.4c0.3,0,0.6,0,0.9,0L99.6,226.4L99.6,226.4z' }),
                m('path', { d: 'M190.5,149.1c-5-12.4-11.9-24.8-20.6-37c-14.7-20.7-29.3-33.5-29.9-34l-1.9-1.7H61.9L60,78.1   c-0.6,0.5-15.2,13.3-29.9,34c-8.7,12.2-15.6,24.7-20.6,37C3.2,164.8,0,180.3,0,195.3c0,9,3.5,17,10.3,23.5   c10.5,10.1,29.1,16.7,55.1,19.6c11.4,1.3,21.6,1.5,28.1,1.5c3.3,0,9.8,0,13.1,0c6.5,0,16.7-0.3,28-1.5c26-2.9,44.6-9.5,55.1-19.6   c6.8-6.5,10.3-14.5,10.3-23.5C200,180.3,196.8,164.8,190.5,149.1z M180.3,209c-14.6,14-51.5,17.2-71.9,17.4v0h-0.5   c-0.3,0-0.6,0-0.9,0v0H93l0,0c-20.1,0-58.3-3-73.3-17.4c-4.1-3.9-6.1-8.4-6.1-13.7c0-30.1,14.9-57.5,27.4-75.1   C51.8,104.9,62.8,94,67.1,90h65.9c9.6,9,53.5,53.1,53.5,105.3C186.4,200.6,184.4,205.1,180.3,209z' }),
                m('path', { d: 'M94.4,178.3c0.3,0.5,0.2,1-0.2,1.5c-0.4,0.5-0.9,0.8-1.4,0.8h-9.2c-0.6,0-1.1-0.1-1.4-0.4   c-0.3-0.3-0.7-0.6-1-1.1l-9-15.6L69,178.8c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5h-8.4c-0.5,0-0.8-0.2-1.1-0.5   c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5h8.4c0.5,0,0.8,0.2,1.1,0.5   c0.3,0.3,0.4,0.7,0.3,1.2L75,150.7l13.1-13.5c0.2-0.3,0.5-0.6,1-0.9c0.4-0.3,1-0.5,1.8-0.5h10.6c0.6,0,0.9,0.2,1.1,0.7   c0.2,0.5,0.1,0.9-0.3,1.3l-19.9,20.3L94.4,178.3z' }),
                m('path', { d: 'M127,135.8c2.5,0,4.8,0.3,6.9,1c2.1,0.7,3.9,1.6,5.4,2.9c1.4,1.3,2.5,2.9,3.1,4.8c0.6,1.9,0.7,4.1,0.2,6.6   c-0.7,3.2-1.9,5.7-3.6,7.6c-1.7,1.9-4,3.4-6.6,4.5l4.7,15.2c0,0.2,0,0.4,0,0.7c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.3-0.7,0.4-1.1,0.4h-8   c-0.9,0-1.4-0.2-1.7-0.6c-0.3-0.4-0.5-0.8-0.6-1.2l-4.1-13.7h-6.5l-2.9,13.7c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5   h-8.4c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5H127z    M116.3,155.1h7.2c1.8,0,3.3-0.3,4.7-1c1.3-0.7,2.2-1.9,2.6-3.7c0.3-1.8,0-3-1-3.7c-1-0.7-2.4-1-4.2-1h-7.2L116.3,155.1z' })
              ])
            ])
          ]),
          m('div', { class: 'inner' }, [
            m('div', { class: 'bar-container' }, [
              m('div', { class: 'bar-container white' }),
              m('div', { class: 'bar-container yellow', style: getProgress() })
            ]),
            m('div', { class: 'left-text' }, d('KenoClassic/BarometerBiggestChance')),
            m('div', { class: 'center-text' }, [
              d('KenoClassic/BarometerPotentialWinnings'),
              // m('span', d('KenoClassic/BarometerPotentialWinningsNotice')),
              m('span', { class: 'notice' }, ' *'),
              m('div', { class: 'total' }, totalWinnings())
            ]),
            m('div', { class: 'right-text' }, d('KenoClassic/BarometerBiggestWinnings'))
          ]),
          m('div', { class: 'right-bags-container' }, [
            m('svg', { class: 'big-bag', viewBox: '0 0 200 303' }, [
              m('g', { class: 'shadow' }, [
                m('path', { d: 'M100,281c55.2,0,100,4.9,100,11c0,6.1-44.8,11-100,11S0,298.1,0,292C0,285.9,44.8,281,100,281z' })
              ]),
              m('g', { class: 'bag' }, [
                m('path', { d: 'M155.4,1.7c-8.7-5.1-21.3,2.1-35.9,10.4c-6.4,3.6-16.1,9.2-19.5,9.3c-3.4-0.1-13-5.7-19.5-9.3   C65.9,3.8,53.3-3.4,44.6,1.7c-2.8,1.6-6.2,5.2-6.2,12.7c0,3.3,1.1,9,10.2,20.5c4.5,5.7,9,10.2,9.2,10.4l2,2h32.7h14.8h32.7l2-2   c0.2-0.2,4.7-4.7,9.2-10.4c9.2-11.5,10.2-17.2,10.2-20.5C161.5,6.9,158.2,3.4,155.4,1.7z M93,33.7H65.6c-1.6-1.7-3.9-4.3-6.2-7.2   c-7.2-8.9-7.4-12.2-7.4-12.2c0-0.3,0-0.5,0-0.7c1.1,0.2,3.2,0.7,6.9,2.3c4.5,2,9.8,5,14.8,7.9c7.1,4,13.6,7.7,19.2,9.6   C93,33.6,93,33.6,93,33.7z M140.6,26.4c-2.3,2.9-4.7,5.5-6.3,7.2h-27.6c0,0,0-0.1,0-0.1c5.6-1.9,12.2-5.6,19.4-9.7   C131.2,21,136.5,18,141,16c3.7-1.7,5.8-2.2,6.9-2.3c0,0.2,0,0.4,0,0.7C147.9,14.4,147.7,17.6,140.6,26.4z' }),
                m('path', { d: 'M100.2,55h-0.5H62.6c-3.8,0-6.8,3.1-6.8,6.8c0,3.8,3,6.8,6.8,6.8h37.2h0.5h37.2c3.8,0,6.8-3.1,6.8-6.8   c0-3.8-3-6.8-6.8-6.8H100.2z' }),
                m('path', { d: 'M99.6,226.4c0.3,0,0.6,0,0.9,0L99.6,226.4L99.6,226.4z' }),
                m('path', { d: 'M190.5,149.1c-5-12.4-11.9-24.8-20.6-37c-14.7-20.7-29.3-33.5-29.9-34l-1.9-1.7H61.9L60,78.1   c-0.6,0.5-15.2,13.3-29.9,34c-8.7,12.2-15.6,24.7-20.6,37C3.2,164.8,0,180.3,0,195.3c0,9,3.5,17,10.3,23.5   c10.5,10.1,29.1,16.7,55.1,19.6c11.4,1.3,21.6,1.5,28.1,1.5c3.3,0,9.8,0,13.1,0c6.5,0,16.7-0.3,28-1.5c26-2.9,44.6-9.5,55.1-19.6   c6.8-6.5,10.3-14.5,10.3-23.5C200,180.3,196.8,164.8,190.5,149.1z M180.3,209c-14.6,14-51.5,17.2-71.9,17.4v0h-0.5   c-0.3,0-0.6,0-0.9,0v0H93l0,0c-20.1,0-58.3-3-73.3-17.4c-4.1-3.9-6.1-8.4-6.1-13.7c0-30.1,14.9-57.5,27.4-75.1   C51.8,104.9,62.8,94,67.1,90h65.9c9.6,9,53.5,53.1,53.5,105.3C186.4,200.6,184.4,205.1,180.3,209z' }),
                m('path', { d: 'M94.4,178.3c0.3,0.5,0.2,1-0.2,1.5c-0.4,0.5-0.9,0.8-1.4,0.8h-9.2c-0.6,0-1.1-0.1-1.4-0.4   c-0.3-0.3-0.7-0.6-1-1.1l-9-15.6L69,178.8c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5h-8.4c-0.5,0-0.8-0.2-1.1-0.5   c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5h8.4c0.5,0,0.8,0.2,1.1,0.5   c0.3,0.3,0.4,0.7,0.3,1.2L75,150.7l13.1-13.5c0.2-0.3,0.5-0.6,1-0.9c0.4-0.3,1-0.5,1.8-0.5h10.6c0.6,0,0.9,0.2,1.1,0.7   c0.2,0.5,0.1,0.9-0.3,1.3l-19.9,20.3L94.4,178.3z' }),
                m('path', { d: 'M127,135.8c2.5,0,4.8,0.3,6.9,1c2.1,0.7,3.9,1.6,5.4,2.9c1.4,1.3,2.5,2.9,3.1,4.8c0.6,1.9,0.7,4.1,0.2,6.6   c-0.7,3.2-1.9,5.7-3.6,7.6c-1.7,1.9-4,3.4-6.6,4.5l4.7,15.2c0,0.2,0,0.4,0,0.7c-0.1,0.4-0.3,0.7-0.6,1c-0.3,0.3-0.7,0.4-1.1,0.4h-8   c-0.9,0-1.4-0.2-1.7-0.6c-0.3-0.4-0.5-0.8-0.6-1.2l-4.1-13.7h-6.5l-2.9,13.7c-0.1,0.5-0.3,0.9-0.7,1.2c-0.4,0.3-0.8,0.5-1.3,0.5   h-8.4c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.4-0.7-0.3-1.2l8.8-41.3c0.1-0.5,0.3-0.9,0.7-1.2c0.4-0.3,0.8-0.5,1.3-0.5H127z    M116.3,155.1h7.2c1.8,0,3.3-0.3,4.7-1c1.3-0.7,2.2-1.9,2.6-3.7c0.3-1.8,0-3-1-3.7c-1-0.7-2.4-1-4.2-1h-7.2L116.3,155.1z' })
              ])
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return Barometer;

  });
