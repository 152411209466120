defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/SliderSelector',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Hammer',
    'DanskeSpil/Framework/NumberGames/Scripts/Framework/HammerTime',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (Hammer, ignoredHammerTime, m, Render) {

    // Template:
    var SliderSelector = function (controller, name, options, value) {

      // Variables:
      var currentValue = value();
      var currentOptions = options();
      var hasTouch = 'ontouchstart' in window;
      var render = Render();

      // Functions:
      var maximumLeft = function () {
        var numbersWidth = controller.property('sliderSelectorNumbersWidth' + name);
        var selectorWidth = controller.property('sliderSelectorSelectorWidth' + name);
        var minLeft = minimumLeft();

        return numbersWidth - selectorWidth - minLeft;
      };

      var minimumLeft = function () {
        return (controller.property('sliderSelectorNumberWidth' + name) / 2) - (controller.property('sliderSelectorSelectorWidth' + name) / 2);
      };

      var selectNumber = function (option) {
        return function () {
          currentOptions = options();
          currentValue = value();

          value(currentOptions[currentOptions.indexOf(option)]);
        };
      };

      var selectorStyle = function () {
        var index = options().indexOf(value());
        var maxLeft = maximumLeft();
        var minLeft = minimumLeft();
        var numbers = controller.property('sliderSelectorNumbers' + name);
        var offset = -(controller.property('sliderSelectorSelectorWidth' + name) / 2);

        if (numbers && numbers.length > 0) {
          for (var i = 0; i <= index; i++) {
            offset += i < index ? numbers[i].$element.clientWidth : numbers[i].$element.clientWidth / 2;
          }
        }

        if (offset < minLeft) {
          offset = minLeft;
        } else if (offset > maxLeft) {
          offset = maxLeft;
        }

        return 'transform: translateX(' + offset + 'px);';
      };

      // Render:
      render.ready.then(function (nodes) {
        var numbers = nodes['number'];
        var numbersAmount = numbers.length;
        var numbersWidth = 0;

        for (var i = 0; i < numbersAmount; i++) {
          numbersWidth += numbers[i].$element.clientWidth;
        }

        controller.property('sliderSelectorNumbers' + name, numbers);
        controller.property('sliderSelectorAverageNumberWidth' + name, numbersWidth / currentOptions.length);
        controller.property('sliderSelectorNumberWidth' + name, nodes['number'][0].$element.clientWidth);
        controller.property('sliderSelectorNumbersWidth' + name, numbersWidth);
        controller.property('sliderSelectorSelectorWidth' + name, nodes['selector'].$element.clientWidth);
        controller.property('sliderSelectorSliderBarWidth' + name, nodes['slider-bar'].$element.clientWidth);

        var selector = nodes['selector'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
        var sliderBar = nodes['slider-bar'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

        if (!nodes['selector'].rendered) {
          var hammer = new Hammer(nodes['selector'].$element);
          var didPan = false;

          hammer.on('panleft panright', function (event) {
            currentValue = value();
            didPan = true;

            var delta = event.deltaX;
            var index = currentOptions.indexOf(currentValue);
            var minLeft = minimumLeft();
            var maxLeft = maximumLeft();
            var offset = 0;

            for (var i = 0; i < index; i++) {
              offset += numbers[i].$element.clientWidth;
            }

            offset += controller.property('sliderSelectorSelectorWidth' + name) / 2;

            delta += offset;

            if (delta < minLeft) {
              delta = minLeft;
            } else if (delta > maxLeft) {
              delta = maxLeft;
            }

            selector.style.transition = 'none';
            selector.style.transform = 'translateX(' + delta + 'px) translateZ(0)';

          });

          hammer.on('panend', function (event) {
            selector.classList.remove('pressed');

            setTimeout(function () {
              didPan = false;
            }, 100);

            currentOptions = options();
            currentValue = value();

            var delta = event.deltaX;

            if (delta < 0) {
              delta -= controller.property('sliderSelectorSelectorWidth' + name) / 2;
            }

            var moved = Math.round(delta / controller.property('sliderSelectorAverageNumberWidth' + name));
            var index = options().indexOf(value()) + moved;

            if (index < 0) {
              index = 0;
            } else if (index > currentOptions.length - 1) {
              index = currentOptions.length - 1;
            }

            value(currentOptions[index]);

            if (currentValue === value()) {
              value(0);
              value(currentValue);
            }

            m.redraw();
          });

          selector.addEventListener(hasTouch ? 'touchstart' : 'mousedown', function () {
            didPan = true;

            selector.classList.add('pressed');
          });

          sliderBar.addEventListener('click', function (event) {

            selector.classList.remove('pressed');

            if (!didPan) {
              currentOptions = options();
              currentValue = value();

              var amount = numbers.length;
              var delta = event.offsetX;
              var index = 0;
              var offset = 0;

              for (var i = 0; i < amount; i++) {
                offset += numbers[i].$element.clientWidth;

                if (delta >= offset) {
                  index++;
                }
              }

              value(currentOptions[index]);

              m.redraw();
            }

            didPan = false;
          });
        }
      });

      // Return:
      return m('div', { class: 'keno-slider-selector', config: render.depend('slider-selector') }, [
        m('div', { class: 'slider-container' }, [
          m('div', { class: 'slider-bar', config: render.depend('slider-bar') }, [
            m('div', { class: 'selector', config: render.depend('selector'), style: selectorStyle() })
          ]),
          m('div', { class: 'numbers-container', config: render.depend('numbers-container') },
            options().map(function (option) {
              return m('span', { class: 'number' + (currentValue === option ? ' selected' : ''), config: render.depend('number'), onclick: selectNumber(option) }, option);
            })
          )
        ])
      ]);

    };

    // Public functions:
    return SliderSelector;

  });
