defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoClassic',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Components/GameClients/KenoClassicClient'
  ],
  function (Component, KenoDictionary, KenoInfo, KenoUtils, KenoClassicClient) {

    // Component:
    Component('keno-classic', [KenoDictionary, KenoInfo], function (m, route, settings, property) {

      // If no draw open, go to the closed page:
      if (KenoInfo.isFeedDown() || KenoInfo.noOpenDraw()) {
        KenoUtils.gotoKenoClosed();
      }

      // Components:
      var client = KenoClassicClient(m, settings, property);

      // Routes:
      route('/', client);

    });

  });
