defineDs('DanskeSpil/Domain/Keno/Scripts/Components/GameClients/KenoSystemClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbers',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowWinnings',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbersHeader',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/DeleteOverlay',
    'DanskeSpil/Domain/Keno/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Keno/Scripts/Templates/SliderSelector',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoSystem/SystemTable'
  ],
  function (Params, Overlay, StatisticsComponentForGameClient, KenoGame, KenoDictionary, KenoInfo, KenoApi, RowNumbers, RowWinnings, RowNumbersHeader, DeleteOverlay, PurchaseBar, SliderSelector, SystemTable) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Variables:
      var d = KenoDictionary.get;

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(KenoGame.setupGame({
            gameInstanceId: this.gameInstanceId(),
            playType: 'KenoSystem',
            systemLevel: 2
          }));
          this.deleteOverlay = m.prop(new Overlay(DeleteOverlay(d)));
          this.levels = m.prop([2, 3, 4, 5, 6, 7, 8, 9]);
          this.systemTables = m.prop();
          this.property = property;
          this.property('currentRow', 1);

          // Functions:
          this.level = function (level) {
            if (typeof level !== 'undefined' && level) {
              var previousLevel = this.game().systemLevel();

              if (previousLevel !== level) {
                this.game().systemLevel(level);
                this.game().save();

                m.redraw();
              }
            }

            return this.game().systemLevel();
          }.bind(this);

          this.rowClass = function () {
            var className = 'row-container row-number-1 in-focus';

            if (this.property('animate') === 'shake') {
              className += ' shake-state';
            }

            return className;
          }.bind(this);

          this.rowConfig = function ($element) {
            var self = this;
            var listenerOptions = { once: true };

            // Event function
            var onAnimationEnd =  function (event) {
              // Remove listener
              event.target.removeEventListener('animationend', onAnimationEnd, listenerOptions);

              self.property('animate', '');
              m.redraw();
            };

            // Add listener
            $element.addEventListener('animationend', onAnimationEnd, listenerOptions); // eslint-disable-line no-jquery/no-other-methods
          }.bind(this);

          // Functions required by PurchaseBar:
          this.purchase = function () {
            var game = this.game();

            // Only submit if we have added something to buy, or we selected "Play as subscription" ie. no draws selected
            if ((game.totalPrice() > 0 || game.numberOfDraws() === 0) && game.getRows().length > 0) {
              game.save();
              location.href = KenoInfo.data().confirmUrl + '?gameInstanceId=' + game.id();
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

          // Load system tables
          KenoApi.getKenoSystemTables().then(function (data) {
            this.systemTables(data);

            m.redraw();
          }.bind(this));

        },

        view: function (controller) {

          // Memoization:
          var game = controller.game();
          var systemLevel = game.systemLevel() + 1;

          // Return:
          return m('div', { class: 'keno-system-game' }, [
            m('div', { class: 'keno-level-slider-section' }, [
              StatisticsComponentForGameClient(controller, 'Keno'),
              settings.heading ? m('span', { class: 'game-heading' }, settings.heading) : null,
              !settings.heading && settings.svgIconName ? m('div', { class: 'game-heading' }, [
                m('svg', { class: settings.svgIconName }, [
                  m('use', { class: settings.svgIconName, href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/SitecoreIcons.svg#' + settings.svgIconName })
                ])
              ]) : null,
              m('div', { class: 'headline' }, d('KenoSystem/LevelSelectorHeadline')),
              SliderSelector(controller, 'KenoSystem', controller.levels, controller.level)
            ]),
            m('div', { class: 'keno-section keno-system-game-section purchase-bar-container' }, [
              m('div', { class: 'keno-content-wrapper' }, [

                // Rows container:
                m('div', { class: 'rows-container' }, [

                  // Row container:
                  m('div', { class: controller.rowClass(), config: controller.rowConfig, onclick: m.redraw }, [

                    // Header:
                    m('div', { class: 'header-rules-text' }, systemLevel === 10 ? d('KenoSystem/RowHeaderDescriptionFull') : d('KenoSystem/RowHeaderDescription', { number: systemLevel })),

                    // Numbers header:
                    RowNumbersHeader(controller, 0),

                    // Table container:
                    m('div', { class: 'number-picker-container' }, RowNumbers(controller, 0))

                  ])
                ]),

                // Purchase bar:
                !settings.subscriptionsMode ? PurchaseBar(controller) : null

              ])
            ]),
            !settings.subscriptionsMode ? RowWinnings(controller) : null,
            !settings.subscriptionsMode ? SystemTable(controller) : null
          ]);
        }
      };

      // Return:
      return root;

    };

    // Public functions:
    return GameClient;

  });
