defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/DeleteOverlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril'
  ],
  function (m) {

    // Template:
    var DeleteOverlay = function (d) {

      // Return:
      return {
        class: m.prop('keno-classic-game-overlay'),

        render: function (confirm, dismiss) {
          return m('div', { class: this.class() }, [
            m('div', { class: 'pannel' }, [
              m('div', { class: 'icon-wrapper' }, [
                m('svg', { class: 'icon icon-rubbish' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-rubbish' })
                ])
              ]),
              m('h2', { class: 'headline' }, d('KenoClassic/OverlayDeleteHeader')),
              m('p', { class: 'text' }, d('KenoClassic/OverlayDeleteBody')),
              m('div', { class: 'buttons' }, [
                m('button', { class: 'btn grey button-cancel', onclick: dismiss }, d('KenoClassic/OverlayDeleteCancel')),
                m('button', { class: 'btn dogderblue button-confirm', onclick: confirm }, d('KenoClassic/OverlayDeleteConfirm'))
              ])
            ])
          ]);
        }
      };
    };

    // Public functions:
    return DeleteOverlay;

  });
