defineDs('DanskeSpil/Domain/Keno/Scripts/Components/GameClients/KenoClassicClient',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Params',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/NumberGames/Scripts/Components/StatisticsComponentForGameClient',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Barometer',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/DeleteOverlay',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Rows',
    'DanskeSpil/Domain/Keno/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowWinnings'
  ],
  function (Params, Overlay, StatisticsComponentForGameClient, KenoGame, KenoDictionary, KenoInfo, Barometer, DeleteOverlay, Rows, PurchaseBar, RowWinnings) {

    // Client:
    var GameClient = function (m, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = KenoDictionary.get;
          this.gameInstanceId = m.prop(Params.extract('gameInstanceId'));
          this.game = m.prop(KenoGame.setupGame({ gameInstanceId: this.gameInstanceId(), playType: 'KenoClassic' }));
          this.deleteOverlay = m.prop(new Overlay(DeleteOverlay(this.d)));
          this.pageReload = m.prop(typeof this.gameInstanceId() !== 'undefined');
          this.property = property;
          this.property('currentRow', 1);

          // Functions:
          this.purchase = function () {
            var game = this.game();

            if (game.getRows().length > 0) {
              game.save();

              location.href = KenoInfo.data().confirmUrl + '?gameInstanceId=' + game.id();
            }
          }.bind(this);

          // Replace state:
          if (!this.gameInstanceId() != this.game().id()) {
            Params.set('gameInstanceId=' + this.game().id());
          }

        },

        view: function (controller) {
          return m('div', { class: 'keno-classic-game' }, [
            StatisticsComponentForGameClient(controller, 'Keno'),
            m('div', { class: 'keno-section keno-classic-game-section purchase-bar-container' }, [
              m('div', { class: 'keno-content-wrapper' }, [
                Barometer(controller, settings),
                Rows(controller),
                !settings.subscriptionsMode ? PurchaseBar(controller) : null
              ])
            ]),
            !settings.subscriptionsMode ? RowWinnings(controller) : null
          ]);
        }
      };

      // Return:
      return root;

    };

    // Public functions:
    return GameClient;

  });
