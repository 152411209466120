defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoFaqPrizes',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SelectDropdown',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Templates/CollapsibleSection'
  ],
  function (Component, SelectDropdown, KenoInfo, KenoDictionary, KenoUtils, CollapsibleSection) {

    // Component:
    Component('keno-faq-prizes', [KenoDictionary, KenoInfo], function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          this.d = KenoDictionary.get;
          this.loading = m.prop(true);
          this.numbers = m.prop([2, 3, 4, 5, 6, 7, 8, 9, 10]);
          this.numbersValue = m.prop(2);
          this.stakes = m.prop(KenoInfo.getStakeOptions().map(function (item) { return { name: '' + item + ' kr', value: item }; }));
          this.stakesValue = m.prop(this.stakes()[0]);

        },

        view: function (controller) {
          return [

            m('.keno-section.bg-superlightblue', [

              // Info and dropdown:
              m('h2.hd', settings.headline || ''),
              m('p', settings.infoText || ''),
              m('.stake-selector', SelectDropdown(controller, controller.stakes, controller.stakesValue, controller.stakesValue)),
            ]),

            m('.keno-section.bg-superlightblue', [

              // Tables
              [10, 9, 8, 7, 6, 5, 4, 3, 2].map(function (no) {
                var prizeGroup = KenoInfo.getPrizeGroups(no);
                var keys = Object.keys(prizeGroup).reverse();
                var prizeGroupMarkup = [];

                for (var i = 0; i < keys.length ; i++) {
                  var key = keys[i];
                  var amount = prizeGroup[key];

                  prizeGroupMarkup.push(m('tr', [
                    m('td', key),
                    m('td', KenoUtils.formatCurrency(amount * controller.stakesValue().value)),

                    i == 0 ? m('td', { rowspan: keys.length }, controller.d('FaqPrizes/TableChanceRatio', { ratio: KenoInfo.getChanceRatio(no) })) : null,
                  ]));
                }

                return CollapsibleSection('table-' + no, {
                  expand: controller.d('FaqPrizes/ExpandButton'),
                  hide: controller.d('FaqPrizes/CompactButton'),
                  hideOnMobile: false
                }, [
                  m('table', { class: 'blue-table' }, [
                    m('thead', { class: 'smaller-font-size' }, [
                      m('tr', [
                        m('th', { colspan: 3 }, controller.d('FaqPrizes/TableHeader', { numbers: no }))
                      ])
                    ]),
                    m('tbody', [
                      m('tr', [
                        m('th', controller.d('FaqPrizes/TableHeaderCorrectNumbers')),
                        m('th', controller.d('FaqPrizes/TableHeaderPrizeAmount')),
                        m('th', controller.d('FaqPrizes/TableHeaderWinningProbability')),
                      ]),
                      prizeGroupMarkup
                    ])
                  ])
                ]);
              })

            ])

          ];

        }
      };

      // Setup routes:
      route('/', root);

    });

  });
