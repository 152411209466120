defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoFaqSelector',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/SelectDropdown',
    'Shared/Framework/Ensighten/Scripts/Ensighten'
  ],
  function (Component, SelectDropdown, Ensighten) {

    // Component:
    Component('keno-faq-selector', [], function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          // Utilities:
          var filterNodeList = function (nodeList, attributeKey, attributeValue) {
            var nodeArray = Array.from(nodeList);
            return nodeArray.filter(function (node) {
              return node.getAttribute(attributeKey) === attributeValue;
            });
          };

          // Variables:
          var $panels = document.querySelectorAll('.panel, .panel > div') || [];

          this.options = m.prop(settings || []);
          this.selectedOption = m.prop(this.options()[0] || '');

          // Functions:
          this.selectQuestion = function (item) {
            this.selectedOption(item);

            // Get content by `data-panelname`
            var $selectedContent = filterNodeList($panels, 'data-panelname', item.value)[0];

            // Return if no content match is found
            if (!$selectedContent) {
              return;
            }

            // Determine if content is panel
            var contentIsPanel = $selectedContent.classList.contains('panel');

            // Get content panel
            var $selectedPanel = contentIsPanel ? $selectedContent : $selectedContent.parentNode;

            // Display selected panel
            $panels.forEach(function (element) { // eslint-disable-line no-jquery/no-other-methods
              element.classList.remove('is-displayed');
            });
            $selectedPanel.classList.add('is-displayed');

            // Scroll to non-panel content
            if (!contentIsPanel) {
              var currentPos = window.pageYOffset;
              var scrollOffset = $selectedContent.getBoundingClientRect().top; // eslint-disable-line no-jquery/no-other-methods
              var bufferTolerance = 100;
              var newPosition = scrollOffset + currentPos - bufferTolerance;

              window.scroll({ top: newPosition, behavior: 'smooth' });
            }

            // Track panel change
            Ensighten.pushVirtualPage(item.name, item.name.replace(/ /g, '-').toLowerCase());

          }.bind(this);

          // Setup:
          if (this.selectedOption()) {
            this.selectQuestion(this.selectedOption());
          }

        },

        view: function (controller) {
          return SelectDropdown(controller, controller.options, controller.selectedOption, controller.selectQuestion);
        }

      };

      // Setup routes:
      route('/', root);

    });

  });
