defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoMillion',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/ErrorOverlay',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Overlay',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Domain/Keno/Scripts/Templates/PurchaseBar',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils'
  ],
  function (Component, ErrorOverlay, Overlay, KenoDictionary, KenoInfo, KenoUtils, KenoGame, Countdown, PurchaseBar, TimeUtils) {
    var d = KenoDictionary.get;

    // Component:
    Component('keno-million', [KenoDictionary, KenoInfo], function (m, route, settings, property) {

      // Components:
      var root = {
        controller: function () {

          // Variables:
          var self = this;
          this.closingDate = m.prop(KenoInfo.data().openDraw ? KenoInfo.data().openDraw.closingTime : '');
          this.errorOverlay = m.prop(new Overlay(ErrorOverlay('keno-million-game-overlay', { header: d('GenerateRowsErrorOverlay/Header'), body: d('GenerateRowsErrorOverlay/Body'), dismiss: d('GenerateRowsErrorOverlay/Dismiss') })));
          this.gameClosed = m.prop(KenoInfo.data().openDraw ? false : true);
          this.isFeedDown = KenoInfo.isFeedDown();
          this.game = m.prop(KenoGame.new({ playType: 'KenoMillion' }));
          this.expanded = m.prop(false);
          this.property = property;
          this.property('currentRow', 1);

          // Functions:
          this.colorStyle = function (color) {
            return color ? 'color: ' + color + ';' : '';
          };

          this.purchase = function () {
            var game = this.game();

            game.save();

            if (this.gameClosed()) {
              KenoUtils.gotoKenoClosed();
            } else {

              this.game().gameAutogenerate().then(function () {
                self.game().save();
                m.redraw();

                if (game.numberOfDraws() === 0) {
                  game.sendToSubscription();
                } else {
                  location.href = KenoInfo.data().confirmUrl + '?gameInstanceId=' + game.id();
                }

              }, function () {
                // Error during autogenerate
                m.redraw();
                self.errorOverlay().show();
              });

            }
          }.bind(this);

          this.expand = function () {
            this.expanded(true);
          }.bind(this);

          this.compact = function () {
            this.expanded(false);
          }.bind(this);

          this.regenerate = function () {

            this.game().gameAutogenerate().then(function () {
              self.game().save();
              m.redraw();
            }, function () {
              m.redraw();
              self.errorOverlay().show();
            });
          }.bind(this);

          this.getCountdownOptions = function () {
            var countdownTime;
            var openDraw = KenoInfo.data().openDraw;
            if (!openDraw) {
              countdownTime = new Date();
              var currentTime = TimeUtils.getCurrentDateTime();
              if (currentTime.getHours() >= 22 || (currentTime.getHours() >= 21 && currentTime.getMinutes() >= 30)) {
                // next day
                countdownTime = new Date(currentTime.getTime() + (24 * 60 * 60 * 1000));
              }

              countdownTime.setHours(21);
              countdownTime.setMinutes(30);
              countdownTime.setSeconds(0);
            }

            return {
              countdownTime: countdownTime ? countdownTime.getTime() / 1000 : 0,
              dictionary: d,
              disabled: false,
              noOpenDraw: true
            };
          }.bind(this);

          this.wrapperCssClasses = function () {
            return 'keno-million purchase-bar-container' +
              (settings.topManchete || settings.bottomManchete ? ' manchete' : '') +
              (settings.topManchete && !this.gameClosed() ? ' top' : '') +
              (settings.bottomManchete ? ' bot' : '') +
              (this.expanded() ? ' is-expanded' : '');
          }.bind(this);

          // Start by regenerating:
          this.regenerate();

          // Add event for regenerate numbers when going back from Safari bfcache (https://www.kimgunnarsson.se/mobile-safari-and-back-forward-cache-invalidation/)
          // window.addEventListener('pageshow', function (e) {
          // if (e.persisted) {
          // self.game().rows([]);
          // m.redraw();
          // self.regenerate();
          // }
          // });

        },

        view: function (controller) {

          if (controller.isFeedDown || controller.gameClosed()) {
            // Feed down or no open draw, content

            return m('div', { class: controller.wrapperCssClasses() },
              m('div', { class: 'game-not-available-wrapper' }, [
                // ICON
                m('svg', { class: 'whitecheckmarkincircle' }, [
                  m('use', { class: 'whitecheckmarkincircle', href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#time-in-circle' })
                ]),
                controller.isFeedDown ? [
                  m('h2', { class: 'keno-million-headline' }, d('KenoClosed/FrontpageKenoMillion/GameNotAvailableHeader')),
                  m('h3', { class: 'subheadline' }, d('KenoClosed/FrontpageKenoMillion/GameNotAvailableText_1')),
                  m('h3', { class: 'subheadline' }, d('KenoClosed/FrontpageKenoMillion/GameNotAvailableText_2'))
                ] : [
                  m('h2', { class: 'keno-million-headline' }, d('KenoClosed/FrontpageKenoMillion/NoOpenDrawHeader')),
                  m('h3', { class: 'subheadline' }, d('KenoClosed/FrontpageKenoMillion/NoOpenDrawText')),
                  Countdown(controller.getCountdownOptions())
                ]
              ])
            );

          }

          // Regular lightning:
          var rowCount = controller.game().rowCount();

          return m('div', { class: controller.wrapperCssClasses() },

            m('section', { class: 'keno-section' }, [
              m('.keno-content-wrapper', [
                // Logo image:
                settings.logoImageUrl ? m('.logo', m('img', { src: settings.logoImageUrl })) : '',

                // Header:
                d('MillionKeno/Header') !== '?' ? m('h2', { class: 'keno-million-headline', style: controller.colorStyle(d('MillionKeno/HeaderColor')) }, settings.headerText) : undefined,

                // Buttons:
                m('.subheading', [
                  m('span', { class: 'subheading-text yellow', style: controller.colorStyle(d('MillionKeno/SubHeaderColor')) }, d('MillionKeno/SubHeader')),
                  m('span', { class: 'subheading-text', style: controller.colorStyle(d('MillionKeno/DescriptionColor')) }, d('MillionKeno/Description'))
                ]),

                // Draw info:
                rowCount > 0 ? [
                  m('div', { class: 'draw-info' }, [
                    m('span', { class: 'draw-info-text' }, d('MillionKeno/DrawInfoRowsAndStake', { rows: rowCount * controller.game().numberOfDraws(), stake: controller.game().stakePerRow(), totalstake: controller.game().totalPrice })),
                    !controller.gameClosed() ? m('span.draw-info-text', m.trust(d(controller.game().numberOfDraws() < 2 ? 'MillionKeno/DrawInfoDrawDateSingular' : 'MillionKeno/DrawInfoDrawDatePluralis', { drawdate: controller.game().drawDateHtml() }))) : '',
                    !controller.gameClosed() ? m('span.draw-info-text', d('MillionKeno/DrawInfoGameStop', { gamestopdate: KenoUtils.formatISO8601(controller.closingDate(), { includeDate: true, includeTime: false }), gamestoptime: KenoUtils.formatISO8601(controller.closingDate(), { includeDate: false, includeTime: true, includeTimePrefix: false, includeYear: false }) })) : '',
                  ]),

                  PurchaseBar(controller)
                ] : ''

              ])
            ])
          );
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
