defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/RowsList',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'Shared/Framework/Mithril/Scripts/Helpers/Scroll',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils'
  ],
  function (OutsideClick, m, Render, Scroll, KenoDictionary, KenoInfo, KenoUtils) {

    // Template:
    var RowsList = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = KenoDictionary.get;
      var game = controller.game();
      var hasTouch = 'ontouchstart' in window;
      var rows = game.getRows(0);
      var rowsAmount = rows.length;
      var render = Render();

      // Functions:
      var getElementHeight = function ($element) {
        return parseFloat(getComputedStyle($element, null).height.replace('px', ''));
      };

      var changeRow = function (rowNumber) {
        return function () {
          if (rowNumber !== currentRow) {
            controller.property('animate', rowNumber < currentRow ? 'left' : 'right');
            controller.property('currentRow', rowNumber);
            controller.property('toggledRowsList', false);
          }
        };
      };

      var deleteRow = function (rowNumber) {
        return function (event) {
          event.stopPropagation();

          controller.property('animate', 'remove');

          if (typeof controller.deleteOverlay !== 'undefined') {
            controller.property('deleteRow', rowNumber);

            controller.deleteOverlay().show(function () {
              var deleteRow = controller.property('deleteRow');
              var game = controller.game();

              if (deleteRow && currentRow !== deleteRow) {
                game.removeRow(deleteRow);

                if (currentRow > deleteRow) {
                  controller.property('currentRow', currentRow - 1);
                }
              } else {
                controller.property('animate', 'remove');

                game.getRow(currentRow).state = 'remove';
              }
            });
          }
        };
      };

      var drawDates = function () {
        var date = KenoInfo.data().openDraw.closingTime;
        var draws = game.numberOfDraws();
        var result = '';

        if (!date || date === '') {
          return d('KenoClassic/RowsListInfo3');
        }

        date = new Date(date);

        if (draws > 1) {
          result = KenoUtils.formatISO8601(date.toISOString(), { includeTime: false, includeYear: false });

          date.setDate(date.getDate() + (draws - 1));

          result = result + ' ' + d('KenoClassic/RowsListInfo5') + ' ' + KenoUtils.formatISO8601(date.toISOString(), { includeYear: false });
        } else {
          result = KenoUtils.formatISO8601(date.toISOString(), { includeYear: false });
        }

        return result;
      };

      var itemsBoxStyle = function () {
        return 'max-height: ' + (controller.property('rowsListParentHeight') - controller.property('rowsListInformationHeight')) + 'px;';
      };

      var rowInit = function (row) {
        return function ($element, rendered) {
          if (!rendered) {
            var activeClassName = 'active';
            var $errorBox = document.querySelector('.error-message-box');

            $element.addEventListener(hasTouch ? 'touchstart' : 'mouseenter', function () { // eslint-disable-line no-jquery/no-other-methods
              if (row.numbers.length < 2) {
                var $headline = $element.querySelector('.headline'); // eslint-disable-line no-jquery/no-other-methods
                var errorMessageBoxHeight = $errorBox.offsetHeight;
                var rowHeadlineHeight = $headline ? getElementHeight($headline) : 0; // eslint-disable-line no-jquery/no-other-methods
                var rowTopPosition = $element.offsetTop;
                var rowsListTopPosition = controller.property('rowsListBoxTopPosition');

                $errorBox.classList.add(activeClassName);
                $errorBox.style.top = (rowsListTopPosition + rowTopPosition + (rowHeadlineHeight / 2) + (errorMessageBoxHeight / 2)) + 'px';

                if (hasTouch) {
                  setTimeout(function () {
                    $errorBox.classList.remove(activeClassName);
                  }, 3000);
                }
              }
            });

            if (!hasTouch) {
              $element.addEventListener('mouseleave', function () { // eslint-disable-line no-jquery/no-other-methods
                $errorBox.classList.remove(activeClassName);
              });
            }
          }
        };
      };

      var getRowsClass = function () {
        var className = 'rows-list-box';

        if (rowsAmount === 0 && controller.property('toggledRowsList')) {
          controller.property('toggledRowsList', false);

          return className;
        } else {
          return className + (controller.property('toggledRowsList') ? ' active' : '');
        }
      };

      var getText = function () {
        return rowsAmount === 1 ? d('KenoClassic/RowsListTitleSingular') : d('KenoClassic/RowsListTitlePluralis', { amount: rowsAmount });
      };

      var toggle = function () {
        controller.property('toggledRowsList', !controller.property('toggledRowsList'));
      };

      // Renders:
      render.ready.then(function (nodes) {
        var $parent = document.querySelector('.rows-container');
        var itemsBox = nodes['items-box'];
        var rowsList = nodes['rows-list'];
        var scrollDown = nodes['scroll-down'];
        var scrollUp = nodes['scroll-up'];

        controller.property('rowsListInformationHeight', nodes['information-box'].$element.offsetHeight + nodes['headline'].$element.offsetHeight);
        controller.property('rowsListParentHeight', $parent ? getElementHeight($parent) : 0);
        controller.property('rowsListBoxHeight', nodes['items-inner-box'].$element.offsetHeight);
        controller.property('rowsListBoxTopPosition', nodes['items-box'].$element.offsetTop);

        controller.property('rowsListShowArrowDown', controller.property('rowsListBoxHeight') - itemsBox.$element.scrollTop > nodes['items-box'].$element.offsetHeight);

        if (!itemsBox.rendered) {
          var scrollTimeout = null;

          Scroll(itemsBox.$element, function () {
            var boxHeight = controller.property('rowsListBoxHeight');
            var scrollTop = itemsBox.$element.scrollTop;

            clearTimeout(scrollTimeout);

            scrollTimeout = setTimeout(function () {
              controller.property('rowsListShowArrowDown', boxHeight - scrollTop > nodes['items-box'].$element.offsetHeight);
              controller.property('rowsListShowArrowUp', scrollTop > 30);

              m.redraw();
            }, 150);
          });
        }

        if (!scrollDown.rendered) {
          scrollDown.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            var height = controller.property('rowsListBoxHeight');
            var distance = (height / controller.game().getRows(0).length) * 2;

            itemsBox.$element.scrollTop += distance + itemsBox.$element.scrollTop > height ? itemsBox.$element.scrollTop : itemsBox.$element.scrollTop + distance;
          });
        }

        if (!scrollUp.rendered) {
          scrollUp.$element.addEventListener('click', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
            itemsBox.$element.scrollTop -= (controller.property('rowsListBoxHeight') / controller.game().getRows(0).length) * 2;
          });
        }

        // Outside click:
        if (!rowsList.rendered) {
          OutsideClick(rowsList.$element, rowsList.context, function () {
            controller.property('toggledRowsList', false);
          });
        }
      });

      // View:
      return m('div', { class: getRowsClass(), config: render.depend('rows-list') }, [
        m('div', { class: 'container' + (rowsAmount === 1 ? ' single-row' : ''), config: render.depend('container') }, [
          m('div', { class: 'informations-box', config: render.depend('information-box') }, [
            m('p', d('KenoClassic/RowsListInfo1', { stake: game.stakePerRow() })),
            m('p', d('KenoClassic/RowsListInfo2', { days: game.numberOfDraws() })),
            m('p', d('KenoClassic/' + (game.numberOfDraws() === 1 ? 'RowsListInfo3' : 'RowsListInfo6'), { date: drawDates() }))
          ]),
          m('div', { class: 'headline', config: render.depend('headline') }, getText()),
          m('div', { class: 'error-message-box', config: render.depend('error-message-box') }, [
            m('div', { class: 'error-headline' }, d('KenoClassic/RowsListErrorTitle')),
            m('div', { class: 'error-subheadline' }, d('KenoClassic/RowsListErrorText'))
          ]),
          m('div', { class: 'items-box', config: render.depend('items-box'), style: itemsBoxStyle() }, [
            m('div', { class: 'scroll-button scroll-up' + (controller.property('rowsListShowArrowUp') ? ' active' : ''), config: render.depend('scroll-up') }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '#icon-up-arrow' })
              ])
            ]),
            m('div', { class: 'items-inner-box', config: render.depend('items-inner-box') }, [
              rows.map(function (row, index) {
                return m('div', { class: 'item' + (row.numbers.length > 1 ? '' : ' invalid-row'), config: rowInit(row) }, [
                  m('div', { onclick: changeRow(index + 1) }, [
                    m('div', { class: 'headline' }, d('KenoClassic/RowsListRow') + ' ' + (index + 1)),
                    m('div', { class: 'numbers' }, row.numbers.map(function (single) {
                      return single.number;
                    }).join(', '))
                  ]),
                  m('div', { class: 'delete-button', onclick: deleteRow(index + 1) }, [
                    m('svg', { class: 'icon-x-big icon' }, [
                      m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-x-big' })
                    ])
                  ])
                ]);
              })
            ]),
            m('div', { class: 'scroll-button scroll-down' + (controller.property('rowsListShowArrowDown') ? ' active' : ''), config: render.depend('scroll-down') }, [
              m('svg', { class: 'icon' }, [
                m('use', { href: '#icon-down-arrow' })
              ])
            ])
          ])
        ]),
        m('div', { class: 'button' + (rowsAmount > 0 ? ' active' : ''), onclick: toggle }, [
          m('svg', { class: 'icon-list' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-list' })
          ]),
          m('svg', { class: 'icon-left-arrow' }, [
            m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-left-arrow' })
          ])
        ])
      ]);
    };

    // Public functions:
    return RowsList;

  });
