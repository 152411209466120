defineDs('DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
  [
    'DanskeSpil/Framework/NumberGames/Scripts/Helpers/Utils'
  ],
  function (Utils) {

    // Functions:
    var gotoKenoClosed = function (replace) {
      if (!window.sitecoreEnv && location.search.indexOf('avoid_redirect') === -1) {
        var url = '/keno/lukket';

        if (replace) {
          try {
            window.history.replaceState({}, 'Keno Lukket', url);
          } catch (error) {
          // Nothing to handle here, just proceed to location.href
          }
        }

        location.href = url;
      }
    };

    // Public functions:
    return {
      gotoKenoClosed: gotoKenoClosed,
      formatCurrency: Utils.formatCurrency,
      formatNumber: Utils.formatNumber,
      formatISO8601: Utils.formatISO8601,
      parseISO8601: Utils.parseISO8601,
      getQueryParam: Utils.getQueryParam,
      prepareParamString: Utils.prepareParamString,
      getLongDate: Utils.getLongDate,
      isMobile: Utils.isMobile,
      isTabletDevice: Utils.isTabletDevice,
      openCoupon: Utils.openCoupon
    };

  });

