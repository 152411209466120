defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoFastPlay',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Models/KenoGame',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils'
  ],
  function (Component, KenoDictionary, KenoInfo, KenoGame, KenoUtils) {

    // Component:
    Component('keno-fast-play', [KenoDictionary], function (m, route, settings) {

      // Components:
      var root = {
        controller: function () {

          this.price = m.prop(0);

          var gameClosed = m.prop(KenoInfo.data().openDraw ? false : true);
          var game = KenoGame.new({ playType: settings.playType });

          if (settings.playType == 'KenoSmall') {
            this.price(game.stakePerRow() * 5 * game.numberOfDraws());
          }
          if (settings.playType == 'KenoLarge') {
            this.price(game.stakePerRow() * 10 * game.numberOfDraws());
          }


          this.confirm = function (event) {
            event.preventDefault();

            if (settings.playType == 'KenoSmall' || settings.playType == 'KenoLarge') {
              game.gameAutogenerate().then(function () {
                game.save();

                if (gameClosed()) {
                  KenoUtils.gotoKenoClosed();
                } else {
                  location.href = KenoInfo.data().confirmUrl + '?gameInstanceId=' + game.id();
                }
              });
            }

          }.bind(this);

        },

        view: function (controller) {

          var sectionStyle = function () {
            var style = '';
            if (settings.backgroundColor) {
              style += 'background-color: ' + settings.backgroundColor + ';';
            }
            if (settings.backgroundImageUrl) {
              style += 'background-image: url(' + settings.backgroundImageUrl + ');';
            }
            return style;
          };

          var getSetting = function (key) {
            return m.trust(settings[key] || '');
          };

          var ctaText = getSetting('ctaText').replace('{price}', controller.price());

          return m('section.keno-section ' + settings.playType, { style: sectionStyle() }, [
            m('.fast-play-game-spot.keno-content-wrapper', [
              m('h2.fastplay-subheadline', [
                getSetting('preHeader')
              ]),
              m('h2.fastplay-headline', [
                getSetting('header')
              ]),
              m('h3.fastplay-subheadline', getSetting('subHeader')),
              m('button.btn.cta.fixed-width-160', { onclick: controller.confirm }, ctaText)
            ])
          ]);
        }
      };

      // Setup routes:
      route('/', root);

    });

  });
