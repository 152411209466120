defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/NoOpenDraw',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',

    'DanskeSpil/Framework/NumberGames/Scripts/Templates/Countdown',
    'DanskeSpil/Framework/TimeUtils/Scripts/TimeUtils'
  ],
  function (m, KenoDictionary, KenoInfo, Countdown, TimeUtils) {

    // View:
    var NoOpenDraw = function () {

      // Variables:
      var d = KenoDictionary.get;
      var isFeedDown = KenoInfo.isFeedDown();

      // Countdown time
      var getCountdownOptions = function () {
        var countdownTime;
        var openDraw = KenoInfo.data().openDraw;
        if (!openDraw) {
          countdownTime = new Date();
          var currentTime = TimeUtils.getCurrentDateTime();
          if (currentTime.getHours() >= 22 || (currentTime.getHours() >= 21 && currentTime.getMinutes() >= 30)) {
            // next day
            countdownTime = new Date(currentTime.getTime() + (24 * 60 * 60 * 1000));
          }

          countdownTime.setHours(21);
          countdownTime.setMinutes(30);
          countdownTime.setSeconds(0);
        }

        return {
          countdownTime: countdownTime ? countdownTime.getTime() / 1000 : 0,
          dictionary: d,
          disabled: false,
          noOpenDraw: true
        };
      };


      // View:
      return m('div', { class: 'keno-no-open-draw bg-powderblue' }, [

        m('section', { class: 'keno-section' }, [
          m('div', { class: 'keno-content-wrapper' }, [
            m('div', { class: 'header-icon' }, [
              m('svg', { class: 'whitecheckmarkincircle' }, [
                m('use', { class: 'whitecheckmarkincircle', href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#time-in-circle' })
              ])
            ]),
            m('h1', { class: 'no-opendraw-headline' }, d('KenoClosed/NoOpenDrawHeader')),
            m('h2', { class: 'no-opendraw-subheadline' }, d('KenoClosed/NoOpenDrawText'))
          ])
        ]),

        m('section', { class: 'keno-section bg-white manchete top' }, [
          m('div', { class: 'keno-content-wrapper' }, [
            (isFeedDown ? [
              m('h2', { class: 'no-opendraw-subheadline content' }, d('KenoClosed/GameNotAvailableHeader')),
              m('h2', { class: 'no-opendraw-subheadline content' }, d('KenoClosed/GameNotAvailableText'))
            ] : [
              Countdown(getCountdownOptions())
            ])
          ])
        ])
      ]);
    };

    // Public functions:
    return NoOpenDraw;

  });
