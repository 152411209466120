defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Rows',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/Navigation',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoClassic/RowsList',
    'DanskeSpil/Domain/Keno/Scripts/Templates/Row',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage'
  ],
  function (m, KenoUtils, Navigation, RowsList, Row, Storage) {

    // Template:
    var Rows = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var game = controller.game();
      var maxRows = game.classicMaxRows();
      var numbers = game.getRow(currentRow).numbers.length;

      // Functions:
      var rowsClass = function () {
        var animate = controller.property('animate');
        var className = 'rows-container';

        if (/left|right/.test(animate)) {
          className += ' slide-to-' + animate;

          if (animate === 'left' && currentRow === 1) {
            className += '-half';
          }
        } else if (currentRow > 1) {
          className += ' rows-static-position';
        }

        if (game.getRow(currentRow).state === 'remove') {
          className += ' delete-row';
        }

        if (!JSON.parse(Storage.get('Preferences-KenoClassicRows-HasAnimated')) && !KenoUtils.isMobile() && numbers >= 2 && numbers < 10 && currentRow === 1 && !controller.property('firstRowShouldAnimate') && !controller.property('firstRowDidAnimate')) {
          setTimeout(function () {
            controller.property('firstRowShouldAnimate', true);

            m.redraw();
          }, 1000);
        }

        if (controller.property('firstRowShouldAnimate')) {
          className += ' first-new-row-valid';

          if (!controller.property('firstRowIsAnimating')) {
            controller.property('firstRowIsAnimating', true);

            setTimeout(function () {
              controller.property('firstRowDidAnimate', true);
              controller.property('firstRowIsAnimating', false);
              controller.property('firstRowShouldAnimate', false);

              Storage.set('Preferences-KenoClassicRows-HasAnimated', JSON.stringify(true));

              m.redraw();
            }, 8000);
          }
        }

        if (numbers >= 2 && currentRow > 1 && currentRow === game.rows().length - 1) {
          className += ' new-row-valid';
        }

        return className;
      };

      var rowsConfig = function ($element, rendered) {
        if (!rendered) {
          $element.addEventListener('animationend', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            var animate = controller.property('animate');
            var currentRow = controller.property('currentRow');
            var rows = game.rows();

            if (/remove/.test(animate)) {
              var clean = [];

              for (var i = 0; i < rows.length; i++) {
                if (rows[i].state !== 'remove') {
                  clean.push(rows[i]);
                }
              }

              for (var j = 0; j < clean.length; j++) {
                clean[j].count = j;
              }

              game.rows(clean);
              game.save();

              if (currentRow > clean.length) {
                game.addRow();

                controller.property('animate', 'left');
              } else {
                controller.property('animate', '');
              }

              m.redraw();
            } else if (animate === 'left' && currentRow === game.rows().length) {
              controller.property('animate', '');
            }
          });
        }
      };

      // View:
      return m('div', { class: rowsClass(), config: rowsConfig }, [
        controller.game().rows().map(function (row, index) {
          if (index < maxRows) {
            return Row(controller, row, index);
          }
        }),
        m('div', { class: 'instructions-wrapper' }, [
          m('div', { class: 'instructions-text-box box-one' }, controller.d('KenoClassic/AddNewRowBoxFirst')),
          m('div', { class: 'instructions-text-box box-two' }, controller.d('KenoClassic/AddNewRowBoxSecond')),
          m('div', { class: 'instructions-text-box box-three' }, controller.d('KenoClassic/AddNewRowBoxThird'))
        ]),
        Navigation(controller), // eslint-disable-line compat/compat
        RowsList(controller)
      ]);

    };

    // Public functions:
    return Rows;

  });
