defineDs('DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/ApiRequest',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils'
  ],
  function (m, ApiRequest, KenoUtils) {

    // Variables:
    var baseURL = '/dlo/scapi/danskespil/numbergames/keno';

    // Functions:
    var prepareParamString = KenoUtils.prepareParamString;

    var createCoupon = function (data) {
      var deferred = m.deferred();

      var req = ApiRequest({
        method: 'POST',
        url: baseURL + '/coupon',
        data: data
      });

      req.then(function (response) {
        response = response || {};

        if (response && !response.Message) {
          deferred.resolve(response);
        } else {
          deferred.reject(response);
        }
      }, function (response) {
        response = response || {};
        response.Message = response.Message || 'ERROR_NOT_GIVEN';
        deferred.reject(response);
      });

      return deferred.promise;
    };

    var getCompletedDrawDates = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/completedDrawDates' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response && response.drawDates ? response.drawDates : [];
        }
      });
    };

    var getCompletedDraws = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/completedDrawDates' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response && response.draws ? response.draws : [];
        }
      });
    };

    var getWinningNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/winningNumbers' + params,
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response;
        }
      });
    };

    // SUPPE!
    var getKenoSystemTables = function () {
      return ApiRequest({
        url: '/Components/DanskeSpil/Domain/Keno/Scripts/kenoSystemTableJson.txt',
        localCacheTTL: 60,
        unwrapSuccess: function (response) {
          return response.systemTables;
        }
      });
    };

    var getKenoInfo = function () {
      var params = KenoUtils.prepareParamString({});

      return ApiRequest({
        url: baseURL + params
      });
    };

    var getRandomNumbers = function (options) {
      var params = prepareParamString(options);

      return ApiRequest({
        url: baseURL + '/random' + params,
        unwrapSuccess: function (response) {
          var rows = [];

          if (response && response.rows) {
            response.rows.map(function (d) {
              rows.push(d.numbers);
            });
          }

          return rows;
        }
      });
    };

    var getCoupon = function (couponId) {
      var params = prepareParamString({ couponId: couponId });

      return ApiRequest({
        url: baseURL + '/coupon' + params
      });
    };

    // Public functions:
    return {
      createCoupon: createCoupon,
      getCompletedDrawDates: getCompletedDrawDates,
      getCompletedDraws: getCompletedDraws,
      getKenoSystemTables: getKenoSystemTables,
      getKenoInfo: getKenoInfo,
      getRandomNumbers: getRandomNumbers,
      getWinningNumbers: getWinningNumbers,
      getCoupon: getCoupon
    };

  });
