defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/KenoSystem/SystemTable',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Templates/CollapsibleSection',
  ],
  function (m, KenoDictionary, CollapsibleSection) {

    // Template:
    var SystemTable = function (controller) {

      // Variables:
      var d = KenoDictionary.get;
      var systemTable = m.prop();

      if (controller.game().systemLevel() && controller.systemTables()) {
        systemTable(controller.systemTables()[controller.game().systemLevel()]);
      }

      if (!systemTable()) {
        return m('p', d('KenoSystem/SystemTable/NoSystemTableAvailableError'));
      }

      if (!controller.systemTables()) {
        return '';
      }

      var factorAmount = systemTable().winningFactors.length;

      // Return:
      return m('div', { class: 'keno-section bg-superlightblue' }, [
        CollapsibleSection('systemtable', { expand: d('KenoSystem/SystemTable/ExpandButton'), hide: d('KenoSystem/SystemTable/CompactButton'), noMobileVersion: d('KenoSystem/SystemTable/NoMobileVersion') }, [
          m('div', { class: 'editor-content' }, [
            m('h2', d('KenoSystem/SystemTable/Header', { level: controller.game().systemLevel() })),
            m('p', { class: 'mobile-hide' }, d('KenoSystem/SystemTable/Paragraph1')),
            m('p', { class: 'mobile-hide' }, d('KenoSystem/SystemTable/Paragraph2'))
          ]),

          m('table', { class: 'blue-table mobile-hide' }, [
            m('thead', { class: 'smaller-font-size' }, [
              m('tr', [
                m('th', d('KenoSystem/SystemTable/TableHeaderNumbersPlayed')),
                m('th', factorAmount < 5 ? d('KenoSystem/SystemTable/TableHeaderCombinations') : d('KenoSystem/SystemTable/TableHeaderCombinationsBreak')),
                m('th', d('KenoSystem/SystemTable/TableHeaderCorrectNumbers')),
                m('th', { colspan: factorAmount + 1 }, d('KenoSystem/SystemTable/TableHeaderPrizeGivingGames'))
              ])
            ]),

            m('tbody', [
              m('tr', [
                m('th', { class: 'empty' }),
                m('th', { class: 'empty' }),
                m('th', { class: 'empty' }),

                systemTable().winningFactors.map(function (factor) {
                  return m('th', d('KenoSystem/SystemTable/TableHeaderPrizeFactor', { factor: factor }));
                }),

                m('th', d('KenoSystem/SystemTable/TableHeaderPrizeKey'))
              ]),

              Object.keys(systemTable().combinations).map(function (i, indexI) {
                var combination = systemTable().combinations[i];

                // Sort so we show highest rightMark first
                var rightMarksKeys = Object.keys(combination.rightMarks).sort(function (a, b) {
                  return parseInt(a, 10) > parseInt(b, 10);
                }).reverse();

                return rightMarksKeys.map(function (j, indexJ) {
                  var rightMark = combination.rightMarks['' + j];
                  var trClass = (indexJ == rightMarksKeys.length - 1 && indexI != Object.keys(systemTable().combinations).length - 1) ? 'bottom-border' : '';

                  return m('tr', { class: trClass }, [
                    m('td', indexJ == 0 ? i : ''), // Antal tal
                    m('td', indexJ == 0 ? combination.combination : ''), // Antal kombinationer
                    m('td', j),

                    rightMark.factors.map(function (factor) {
                      return m('td', factor);
                    }),

                    m('td', rightMark.key)
                  ]);
                });
              })
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return SystemTable;

  });
