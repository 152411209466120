defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/CollapsibleSection',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render'
  ],
  function (m, Render) {

    // Vars
    var persistent = {};

    // Template:
    var CollapsibleSection = function (id, options, content) {

      // Persistent variables
      persistent[id] = persistent[id] || {};
      var s = persistent[id];
      s.expanded = s.expanded || m.prop(false);
      s.wrapperHeight = s.wrapperHeight || m.prop();

      // Variables:
      var render = Render();
      var hideOnMobile = typeof options.hideOnMobile !== 'undefined' ? options.hideOnMobile : true;

      // Functions:
      var toggleExpand = function () {
        s.expanded(!s.expanded());
      };

      // Renders:
      render.ready.then(function (nodes) {
        var $collapsibleInnerWrapper = nodes['inner-collapsible-section'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
        var height = $collapsibleInnerWrapper.offsetHeight;

        if (height != s.wrapperHeight()) {
          s.wrapperHeight(height);

          if (s.expanded()) {
            var $collapsibleWrapper = nodes['collapsible-section'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element

            $collapsibleWrapper.style.maxHeight = height + 'px';
          }
        }
      });


      // View:controller.systemTables()
      return [
        m('div', {
          class: 'keno-content-wrapper box-wide keno-collapsible-section ' + (s.expanded() ? ' active' : ''),
          style: (s.expanded() ? 'max-height: ' + s.wrapperHeight() + 'px' : ''),
          config: render.depend('collapsible-section')
        }, [
          m('div', { class: 'inner-collapsible-section', config: render.depend('inner-collapsible-section') }, [

            content,

            m('div', {
              class: 'expand-button-container' + (hideOnMobile ? ' mobile-hide' : ''),
              onclick: toggleExpand
            }, [
              m('div', { class: 'button' }, [
                m('span', { class: 'expand-text' }, options.expand),
                m('span', { class: 'collapse-text' }, options.hide),
                m('svg', { class: 'svg-icon-arrow-up rotate180' }, [
                  m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoCommonIcons.svg#uparrow-small' })
                ])
              ])
            ])
          ]),
          hideOnMobile ? m('p', { class: 'mobile-message mobile-only' }, options.noMobileVersion) : '',
        ])
      ];

    };

    // Public functions:
    return CollapsibleSection;

  });
