defineDs('DanskeSpil/Domain/Keno/Scripts/NonMithril/KenoTop', function () {

  // Variables:
  var $video = document.querySelector('.keno-top-spot .keno-video');

  var setVideoState = function () {
    $video.classList.remove('is-loading');
    $video.classList.add('can-playthrough');
  };

  // Context:
  if ($video) {
    $video.addEventListener('canplaythrough', function () { // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
      setVideoState();
    }, false);

    // If the video is in the cache of the browser,
    // the 'canplaythrough' event might have been triggered
    // before we registered the event handler.
    if ($video.readyState > 3) {
      setVideoState();
    }
  }


});
