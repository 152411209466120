defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoGameClosed',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Templates/NoOpenDraw'
  ],
  function (Component, KenoDictionary, KenoInfo, NoOpenDraw) {

    // Component:
    Component('keno-game-closed', [KenoDictionary, KenoInfo], function (ignoredM, route) {

      // Feed check:
      if (!KenoInfo.isFeedDown() && !KenoInfo.noOpenDraw()) {
        location.href = '/keno';
        return;
      }

      // Components:
      var root = {
        controller: function () {
        },

        view: function (controller) {
          return NoOpenDraw(controller);
        }

      };

      // Setup routes:
      route('/', root);

    });

  });

