defineDs('DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Dictionary'
  ],
  function (Dictionary) {

    // Variables:
    var kenoDictionary = new Dictionary('/NumberGames/Keno');

    // Public functions:
    return kenoDictionary;

  });
