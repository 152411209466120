defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/RowWinnings',
  [
    'Shared/Framework/Mithril/Scripts/Helpers/Hammer',
    'DanskeSpil/Framework/NumberGames/Scripts/Framework/HammerTime',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Render',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Templates/KenoSystem/LevelWinnings'
  ],
  function (Hammer, ignoredHammerTime, m, Render, KenoDictionary, KenoInfo, KenoUtils, LevelWinnings) {

    // View:
    var RowWinnings = function (controller) {

      // Variables:
      var currentRow = controller.property('currentRow');
      var d = KenoDictionary.get;
      var game = controller.game();
      var numbers = game.getRow(currentRow).numbers.length;
      var numbersPerRowMin = game.numbersPerRowMin();
      var render = Render();
      var totalPrice = game.totalPrice();
      var validRows = game.getRowsSummary(true).length;
      var winnings = numbers < 2 ? {} : KenoInfo.getPrizeGroups(numbers);
      var winningNumbers = winnings ? Object.keys(winnings).reverse() : [];

      // remove 0 from the winningnumber array on system keno
      if (game.playType() === 'KenoSystem' && winningNumbers.indexOf('0') > -1) winningNumbers.splice(winningNumbers.indexOf('0'), 1);
      var winningNumbersAmount = winningNumbers.length;
      var winningFactors = [];

      if (game.playType() === 'KenoSystem' && controller.systemTables() && numbers > game.systemLevel()) {
        var systemTable = controller.systemTables()[game.systemLevel()];
        var rightMarks = systemTable.combinations[numbers].rightMarks;
        var keys = Object.keys(rightMarks);

        for (var i = 0; i < keys.length; i++) {
          var key = rightMarks[keys[i]].key;

          if (key) {
            winningFactors.push(key);
          }
        }

        winningFactors.reverse();
      }

      if (!controller.property('rowWinningsNumbers')) {
        controller.property('rowWinningsNumbers', numbers);
      }

      if (controller.property('rowWinningsNumbers') !== numbers) {
        controller.property('rowWinningsNumbers', numbers);
      }

      if (typeof controller.property('rowWinningsSelected') === 'undefined' || winningNumbersAmount !== controller.property('rowWinningsNumbersAmount')) {
        controller.property('rowWinningsSelected', 0);
      }

      controller.property('rowWinningsNumbersAmount', winningNumbersAmount);

      var selectedIndex = parseInt(controller.property('rowWinningsSelected') || 0, 10);

      // Functions:
      var changeNumber = function (direction) {
        return function () {
          if (!controller.property('rowWinningsIsPanning')) {
            selectedIndex = controller.property('rowWinningsSelected');
            winningNumbersAmount = controller.property('rowWinningsNumbersAmount');

            if (direction === 'previous') {
              selectedIndex++;

              if (selectedIndex > winningNumbersAmount - 1) {
                selectedIndex = winningNumbersAmount - 1;
              }
            } else if (direction === 'next') {
              selectedIndex--;

              if (selectedIndex < 0) {
                selectedIndex = 0;
              }
            } else {
              selectedIndex = winningNumbers.indexOf(winningNumbers[direction]);
            }

            controller.property('rowWinningsSelected', selectedIndex);

            m.redraw();
          }
        };
      };

      var nextButtonClass = function () {
        var className = 'next-number-button';

        if (controller.property('rowWinningsSelected') > 0) {
          className = className + ' active';
        }

        return className;
      };

      var numbersStyle = function () {
        var style = 'transform: translateX(0) translateZ(0);';
        var width = controller.property('rowWinningsNumberWidth');

        if (width && width !== 0 && controller.property('rowWinningsNumbersAmount') >= numbersPerRowMin) {
          var translateX = width * controller.property('rowWinningsSelected');

          controller.property('rowWinningsTranslateX', translateX);

          style = 'transform: translateX(' + translateX + 'px) translateZ(0);';
        }

        return style;
      };

      var previousButtonClass = function () {
        var className = 'prev-number-button';

        if (controller.property('rowWinningsSelected') < controller.property('rowWinningsNumbersAmount') - 1) {
          className = className + ' active';
        }

        return className;
      };

      var winningsStyle = function (index) {
        return 'transform: scale(1' + (index === controller.property('rowWinningsSelected') ? '.25' : '') + ') translateZ(0);';
      };

      // Render:
      render.ready.then(function (nodes) {
        var $winningsInnerWrapper = nodes['winnings-inner-wrapper'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
        var rowsNumbersBlue = nodes['rows-numbers-blue'];
        var selectedIndex = controller.property('rowWinningsSelected');
        var winningNumbersAmount = controller.property('rowWinningsNumbersAmount');
        var wrapper = nodes['rows-numbers-wrapper'];

        if (!rowsNumbersBlue.rendered) {
          controller.property('rowWinningsNumberWidth', parseInt(window.getComputedStyle(rowsNumbersBlue.$element).getPropertyValue('width'), 10));
        }

        $winningsInnerWrapper.style.transform = 'translateY(' + (winningNumbersAmount === 0 ? '0' : (winningNumbersAmount - selectedIndex - 1) * ($winningsInnerWrapper.clientHeight / winningNumbersAmount)) + 'px) translateZ(0)';

        if (!wrapper.rendered) {
          var $blueNumbers = nodes['blue-numbers'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
          var $whiteNumbers = nodes['white-numbers'].$element; // eslint-disable-line no-jquery/variable-pattern -- Reason: Not a jquery element
          var hammer = new Hammer(wrapper.$element);
          var width = controller.property('rowWinningsNumberWidth');

          hammer.on('panleft panright', function (event) {
            controller.property('rowWinningsIsPanning', true);

            var selectedIndex = controller.property('rowWinningsSelected');
            var delta = event.deltaX + (selectedIndex * width);
            var winningNumbersAmount = controller.property('rowWinningsNumbersAmount');

            if (delta < 0) {
              delta = 0;
            } else if (delta > (winningNumbersAmount - 1) * width) {
              delta = (winningNumbersAmount - 1) * width;
            }

            $blueNumbers.style.transition = 'none';
            $blueNumbers.style.transform = 'translateX(' + delta + 'px) translateZ(0)';
            $whiteNumbers.style.transition = 'none';
            $whiteNumbers.style.transform = 'translateX(' + delta + 'px) translateZ(0)';
          });

          hammer.on('panend', function (event) {
            var selectedIndex = controller.property('rowWinningsSelected');
            var winningNumbersAmount = controller.property('rowWinningsNumbersAmount');

            selectedIndex += Math.round(event.deltaX / width);

            if (selectedIndex < 0) {
              selectedIndex = 0;
            } else if (selectedIndex > winningNumbersAmount - 1) {
              selectedIndex = winningNumbersAmount - 1;
            }

            $blueNumbers.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element
            $whiteNumbers.removeAttribute('style'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery element

            if (controller.property('rowWinningsSelected') !== selectedIndex) {
              controller.property('rowWinningsSelected', selectedIndex);
            } else {
              $blueNumbers.style.transform = 'translateX(' + controller.property('rowWinningsTranslateX') + 'px) translateZ(0)';
              $whiteNumbers.style.transform = 'translateX(' + controller.property('rowWinningsTranslateX') + 'px) translateZ(0)';
            }

            m.redraw();

            setTimeout(function () {
              controller.property('rowWinningsIsPanning', false);
            }, 200);
          });
        }
      });

      // Return:
      return m('div', { class: 'keno-section bg-superlightblue keno-winnings-section', config: render.depend() }, [
        m('h2', { class: 'winnings-headline' }, d('WinningsOverview/Headline')),

        game.playType() === 'KenoSystem' ? [
          m('h3', { class: 'winnings-subheadline' }, d('WinningsOverview/LevelWinningsHeadline')),
          LevelWinnings(controller),
        ] : null,

        m('h3', { class: 'winnings-subheadline' }, d('WinningsOverview/WinningsCorrect')),
        m('div', { class: 'rows-numbers-wrapper', config: render.depend('rows-numbers-wrapper') }, [
          m('div', { class: 'rows-numbers-blue', config: render.depend('rows-numbers-blue') }, [
            m('div', { class: 'rows-numbers-inner-container', config: render.depend('blue-numbers'), style: numbersStyle() }, [
              winningNumbers.map(function (number, index) {
                return m('span', { class: 'row-number-box', onmouseup: changeNumber(index) }, [
                  m('span', { class: 'row-number-text' }, number)
                ]);
              })
            ])
          ]),
          m('div', { class: 'rows-numbers-background' }),
          m('div', { class: 'rows-numbers-white' }, [
            m('div', { class: 'rows-numbers-inner-container', config: render.depend('white-numbers'), style: numbersStyle() }, [
              winningNumbers.map(function (number) {
                return m('span', { class: 'row-number-box' }, [
                  m('span', { class: 'row-number-text' }, number)
                ]);
              }),
              function () {
                if (numbers < 2) {
                  return m('span', { class: 'row-number-box' }, [
                    m('span', { class: 'row-number-text' }, '-')
                  ]);
                }
              }()
            ])
          ]),
          m('div', { class: previousButtonClass(), onclick: changeNumber('previous') }, [
            m('svg', { class: 'next-number-button__icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-left-arrow' })
            ])
          ]),
          m('div', { class: nextButtonClass(), onclick: changeNumber('next') }, [
            m('svg', { class: 'next-number-button__icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-right-arrow' })
            ])
          ])
        ]),
        m('h3', { class: 'winnings-subheadline' }, d('WinningsOverview/WinningsPossibleWinnings')),
        m('div', { class: 'winnings-wrapper' }, [
          m('div', { class: 'winnings-inner-wrapper', config: render.depend('winnings-inner-wrapper') }, [
            game.playType() === 'KenoSystem' ? [
              winningFactors.map(function (number, index) {
                return m('div', { class: 'winning-amount', style: winningsStyle(index) }, KenoUtils.formatCurrency((totalPrice * number) / game.getSystemCombinationAmount()));
              })
            ] : [
              winningNumbers.map(function (number, index) {
                return m('div', { class: 'winning-amount', style: winningsStyle(index) }, KenoUtils.formatCurrency((totalPrice * winnings[number]) / validRows));
              })
            ],
            function () {
              if (numbers < 2) {
                return m('div', { class: 'winning-amount' }, '0 kr.');
              }
            }()
          ]),
          m('div', { class: 'winnings-top-gradient' }),
          m('div', { class: 'winnings-bottom-gradient' })
        ]),
        m('h3', { class: 'winnings-subheadline winnings-notice' }, [
          m('span', { class: 'notice' }, '* '),
          d('WinningsOverview/WinningsMax')
        ])
      ]);

    };

    // Public functions:
    return RowWinnings;

  });
