defineDs('DanskeSpil/Domain/Keno/Scripts/Components/KenoSystem',
  [
    'Shared/Framework/Mithril/Scripts/Core/Component',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoUtils',
    'DanskeSpil/Domain/Keno/Scripts/Components/GameClients/KenoSystemClient'
  ],
  function (Component, KenoDictionary, KenoInfo, KenoUtils, KenoSystemClient) {

    // Component:
    Component('keno-system', [KenoDictionary, KenoInfo], function (m, route, settings, property) {

      // If no draw open, go to the closed page:
      if (!KenoInfo.data().openDraw) {
        KenoUtils.gotoKenoClosed();
      }

      // Components:
      var client = KenoSystemClient(m, settings, property);

      // Routes:
      route('/', client);

    });

  });