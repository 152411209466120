defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbers',
  [
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowNumber'
  ],
  function (m, RowNumber) {

    // Context:
    var RowNumbers = function (controller, rowNumber) {

      // Variables:
      var dom = [];
      var game = controller.game();
      var number = 0;
      var totalNumbers = game.numbersIntervalMax();
      var currentRow = controller.property('currentRow');

      // Functions:
      var createNumberConfig = function (number) {
        if (rowNumber + 1 !== currentRow) {
          return;
        }

        return function ($element, rendered, context) {
          if (rendered && context.rowNumber === rowNumber) {
            return false;
          }

          context.rowNumber = rowNumber;

          var activeClassName = 'active';
          var touchClick = false;
          var touchEnd = false;
          var touchMove = false;
          var touchTimer = null;

          // Event functions

          var onClick = function () {
            if (!touchClick && !controller.property('autogeneratingNumbers')) {
              numberCounter(number);
            }
          };

          var onTouchStart = function () {
            if (!controller.property('autogeneratingNumbers')) {
              clearTimeout(touchTimer);

              touchClick = false;
              touchEnd = false;
              touchMove = false;

              touchTimer = setTimeout(function () {
                if (!touchEnd && !touchMove) {
                  touchClick = true;

                  $element.classList.add(activeClassName); // eslint-disable-line no-jquery/no-other-methods
                }
              }, 250);
            }
          };

          var onTouchEnd = function () {
            if (!controller.property('autogeneratingNumbers')) {
              touchEnd = true;

              if (touchClick) {
                $element.classList.remove(activeClassName); // eslint-disable-line no-jquery/no-other-methods

                numberCounter(number);

                m.redraw();
              }
            }
          };

          var onTouchMove = function onTouchMove() {
            touchMove = true;
          };

          var onAnimationEnd = function onAnimationEnd() {
            controller.property('numberSelected' + number, false);
          };

          // Event listeners

          $element.addEventListener('click', onClick); // eslint-disable-line no-jquery/no-other-methods

          $element.addEventListener('touchstart', onTouchStart); // eslint-disable-line no-jquery/no-other-methods

          $element.addEventListener('touchend', onTouchEnd); // eslint-disable-line no-jquery/no-other-methods

          $element.addEventListener('touchmove', onTouchMove); // eslint-disable-line no-jquery/no-other-methods

          $element.addEventListener('animationend', onAnimationEnd); // eslint-disable-line no-jquery/no-other-methods
        };
      };

      var numberCounter = function (number) {
        var hasNumber = game.hasNumber(currentRow, number);
        var nextRow = game.getRow(currentRow + 1);
        var numbers = game.getRow(currentRow).numbers.length;

        if (numbers < game.numbersPerRowMax() && !hasNumber) {
          game.addNumber(currentRow, number);

          if (numbers > 0 && !nextRow) {
            game.addRow();
          }

          controller.property('currentNumbers', 'increase');
        } else if (hasNumber) {
          game.removeNumber(currentRow, number);

          if (numbers < game.numbersPerRowMin() + 1 && nextRow && nextRow.numbers.length === 0) {
            game.removeRow(currentRow + 1);
          }

          controller.property('currentNumbers', 'decrease');
        } else {
          controller.property('animate', 'shake');

          return;
        }

        controller.property('numberSelected' + number, true);
      };

      // View:
      while (number < totalNumbers) {
        number++;

        var className = '';
        var hasNumber = game.hasNumber(rowNumber + 1, number);

        if (hasNumber) {
          className = className + ' selected';
        }

        if (controller.property('numberSelected' + number) && rowNumber + 1 === currentRow) {
          className = className + ' flip-animation-in-progress ' + (hasNumber ? '' : 'de') + 'select-number-animation';
        }

        dom.push(RowNumber(number, className, createNumberConfig(number)));
      }

      return dom;
    };

    // Public functions:
    return RowNumbers;

  });
