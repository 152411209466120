defineDs('DanskeSpil/Domain/Keno/Scripts/Templates/RowNumbersHeader',
  [
    'Common/Framework/EventHandling/Scripts/OutsideClick',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoDictionary',
    'DanskeSpil/Domain/Keno/Scripts/Templates/RowAutogenerate'
  ],
  function (OutsideClick, m, KenoDictionary, RowAutogenerate) {

    // Template:
    var RowNumbersHeader = function (controller, rowNumber) {

      // Variables:
      var d = KenoDictionary.get;
      var game = controller.game();
      var nextRow = game.getRow(rowNumber + 2);
      var numbers = game.getRow(rowNumber + 1).numbers;
      var numbersAmount = numbers.length;
      var playType = game.playType();

      // Functions:
      var resetRow = function () {
        for (var i = 0; i < numbersAmount; i++) {
          controller.property('numberSelected' + numbers[i].number, 'reset');
        }

        game.resetRow(rowNumber + 1);

        if (nextRow && nextRow.numbers.length === 0) {
          game.removeRow(rowNumber + 2);
        }
      };

      var toggle = function () {
        controller.property('toggledAutogenerate', !controller.property('toggledAutogenerate'));
      };

      var toggleAutogenerate = function (rowNumber) {
        return function ($element, rendered, context) {
          if (!rendered) {
            context.suppe = Math.random();

            OutsideClick($element, context, function () {
              if (rowNumber + 1 === controller.property('currentRow')) {
                controller.property('toggledAutogenerate', false);
              }
            });
          }
        };
      };

      // View:
      return m('div', { class: 'number-picker-footer clearfix' }, [

        // Keno Classic:
        function () {
          if (playType === 'KenoClassic') {
            return m('span', { class: 'counter-box' }, [
              d('KenoClassic/NumbersHeaderChosen') + ' ',
              m('span', { class: 'total' }, numbersAmount)
            ]);
          }
        }(),

        // Keno System:
        function () {
          if (playType === 'KenoSystem') {
            return m('div', { class: 'counter-container' }, [
              m('span', { class: 'counter-box' }, [
                m('span', { class: 'mobile-hide' }, d('KenoSystem/NumbersHeaderCombinationDesktop')),
                m('span', { class: 'mobile-only' }, d('KenoSystem/NumbersHeaderCombinationMobile')),
                ' ',
                m('span', { class: 'total' }, game.getSystemCombinationAmount())
              ]),
              m('span', { class: 'counter-box' }, [
                m('span', { class: 'mobile-hide' }, d('KenoSystem/NumbersHeaderChosenDesktop')),
                m('span', { class: 'mobile-only' }, d('KenoSystem/NumbersHeaderChosenMobile')),
                ' ',
                m('span', { class: 'total' }, [
                  numbersAmount + ' ',
                  m('span', { class: 'mobile-hide' }, d('KenoSystem/NumbersHeaderNumber'))
                ])
              ])
            ]);
          }
        }(),

        m('div', { class: 'buttons-container', key: 'row-numbers-header-' + rowNumber }, [
          m('div', { class: 'autogenerate-box' + (controller.property('toggledAutogenerate') && controller.property('currentRow') === rowNumber + 1 ? ' active' : '') + (numbersAmount === 10 ? ' inactive' : ''), config: toggleAutogenerate(rowNumber) }, [
            m('span', { class: 'autogenerate-button button', onclick: toggle }, [
              m('span', { class: 'mobile-only' }, d('KenoClassic/NumbersHeaderAutoMobile')),
              m('span', { class: 'mobile-hide' }, d('KenoClassic/NumbersHeaderAutoDesktop')),
              m('svg', { class: 'icon-auto icon' }, [
                m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-auto' })
              ])
            ]),
            m('div', { class: 'options clearfix' + (playType === 'KenoSystem' ? ' eight-cells' : ' ten-cells') },
              RowAutogenerate(controller))
          ]),
          m('span', { class: 'reset-row-button button' + (numbersAmount === 0 ? ' inactive' : ''), onclick: resetRow }, [
            m('span', { class: 'mobile-only' }, d('KenoClassic/NumbersHeaderClearMobile')),
            m('span', { class: 'mobile-hide' }, d('KenoClassic/NumbersHeaderClearDesktop')),
            m('svg', { class: 'icon-rubbish icon' }, [
              m('use', { href: '/Components/DanskeSpil/Domain/Keno/Graphics/SpriteSheets/KenoClassicIcons.svg#icon-rubbish' })
            ])
          ])
        ])
      ]);

    };

    // Public functions:
    return RowNumbersHeader;

  });
