defineDs('DanskeSpil/Domain/Keno/Scripts/Helpers/KenoInfo',
  [
    'DanskeSpil/Domain/Keno/Scripts/Helpers/KenoApi',
    'Shared/Framework/Mithril/Scripts/Core/Mithril',
    'Shared/Framework/Mithril/Scripts/Helpers/Storage',

  ],
  function (KenoApi, m, Storage) {

    // Variables:
    var data = m.prop({});
    var isFeedDown = m.prop(false);
    var noOpenDraw = m.prop(false);
    var ready = m.deferred();

    // Functions:
    var getStakeOptions = function () {
      return data().stakeOptions ? data().stakeOptions.map(function (v) { return v.Value; }) : [];
    };

    var getStakeDefault = function () {
      var defaults = data().stakeOptions ? data().stakeOptions.filter(function (v) { return v.IsDefault; }).map(function (v) { return v.Value; }) : [];
      return defaults[0] || null;
    };

    // Draw Repeat Options:

    var getDrawRepeatOptions = function () {
      var options = data().drawRepeatOptions && data().drawRepeatOptions.options ? data().drawRepeatOptions.options : [];

      // Filter: Draw repeat value should not be higher than known amount of upcomming draws
      options = options.filter(function (v) {
        return v <= data().draws.length;
      });

      // Sort
      options.sort(function (a, b) { return a > b ? 1 : -1; });

      return options;
    };

    var getDrawRepeatDefault = function () {
      return data().drawRepeatOptions && data().drawRepeatOptions.default ? data().drawRepeatOptions.default : 1;
    };

    var getChanceRatio = function (rowLength) {
      var prizes = null;

      for (var i = 0; i < data().prizes.length; i++) {
        if (data().prizes[i].rowLength == rowLength) {
          prizes = data().prizes[i];
        }
      }

      return prizes ? prizes.chanceRatio : null;
    };

    var getPrizeGroups = function (rowLength) {
      if (typeof data().prizes !== 'undefined') {

        // Find right price group
        var prizes = null;

        for (var i = 0; i < data().prizes.length; i++) {
          if (data().prizes[i].rowLength == rowLength) {
            prizes = data().prizes[i];
          }
        }

        // Build output structure
        if (prizes) {
          var prizeGroups = {};

          for (var j = 0; j < prizes.prizeGroups.length; j++) {
            var record = prizes.prizeGroups[j];

            prizeGroups[record.correctNumbers] = record.winning;
          }

          return prizeGroups;
        }
      }

      return null;
    };

    // Open draw:
    var getOpenDraw = function () {
      return data().openDraw;
    };

    var feedValid = function (str) {
      try {
        var json = JSON.parse(str);

        if (json && typeof json.openDraw !== 'undefined') {
          return true;
        }
      } catch (e) { }

      return false;
    };

    var storeToCache = function (json) {
    // Store with TTL 2 min.
      Storage.set('KenoInfoFeed', json ? JSON.stringify(json) : null, 120);
    };

    // Initialize:
    var init = function () {

      // 1) LOOK IN DOM

      var $kenoFeedData = document.querySelector('#keno-info');
      if ($kenoFeedData) {
        var domData = $kenoFeedData.innerHTML;

        if (feedValid(domData)) {
          var domJson = JSON.parse(domData);
          data(domJson);

          noOpenDraw(!data().openDraw);

          // Store to cache
          storeToCache(domJson);
          ready.resolve();
          return;
        }
      }


      // 2) LOOK IN LOCALSTORAGE
      var cacheData = Storage.get('KenoInfoFeed');

      if (!cacheData || !feedValid(cacheData)) {
      // Clear cache
        storeToCache(null);
      } else {
      // Use cache
        data(JSON.parse(cacheData));

        noOpenDraw(!data().openDraw);

        ready.resolve();
        return;
      }

      // 3) LOAD INFO FROM FEED

      KenoApi.getKenoInfo().then(function (d) {
        data(d);

        noOpenDraw(!data().openDraw);

        // Store to cache
        storeToCache(d);

        ready.resolve();
      }, function () {
        isFeedDown(true);
        noOpenDraw(true);

        ready.resolve();

        console.error('### KENOINFO FEED DOWN ###');
      });
    };

    init();

    // Public functions:
    return {
      data: data,
      getOpenDraw: getOpenDraw,
      isFeedDown: isFeedDown,
      noOpenDraw: noOpenDraw,
      ready: ready.promise,
      getStakeOptions: getStakeOptions,
      getStakeDefault: getStakeDefault,
      getDrawRepeatOptions: getDrawRepeatOptions,
      getDrawRepeatDefault: getDrawRepeatDefault,
      getPrizeGroups: getPrizeGroups,
      getChanceRatio: getChanceRatio
    };

  });
